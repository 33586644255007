import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import { Box, Button, Typography, CircularProgress, Select as MuiSelect, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useStateContext } from "../../contexts/ContextProvider";
import { getApproveeLeaveTaken, actionLeaveTaken, getLeavePlannerData, buildModifiedTimesheetData,
  generateMonthList, buildInitialCombinedApprovalData, getTimesheetData, actionTimesheet
} from "../../services/leaveService";
import CustomDataGrid from '../../components/CustomDataGrid';
import CollapsableCard from '../../components/CollapsableCard';
import Popup from "../../components/Popup";
import LeaveApprovalForm from "../leave_application_form/leave_approval_form";
import Select from 'react-select';
import moment from 'moment';
import LoadingOverlay from '../global/LoadingOverlay';
import * as XLSX from 'xlsx';
import CustomSnackBar from '../../components/CustomSnackBar';
// import FormControl from '@mui/material';
// import InputLabel from '@mui/material';
// import MenuItem from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { number } from 'yup';
import { styled } from '@mui/system';
import DataFilter from '../global/DataFilter';


const ApproveTimesheetsPage = () => {
  let { user, employees, setEmployees, openLeaveApplicationPopup, setOpenLeaveApplicationPopup, 
        openFailedPopup, setOpenFailedPopup, resultMessage, isLoading, setIsLoading, setSnackbar,
        timesheetData, timesheetSettings, months, setTimesheetData, setTimesheetSettings, setMonths, jobs, setJobs,
        combinedApprovalData, setCombinedApprovalData, parmCodes, setParmCodes, dayTypes, setDayTypes, timesheetApprovers, setTimesheetApprovers,
        payrollUsers, setPayrollUsers, payrollRights, setPayrollRights, initialEmployees, setInitialEmployees, selectedActivity,
        allPayrolls, setAllPayrolls, allPayPoints, setAllPayPoints, allCostCodes, setAllCostCodes, modifiedApproveTimesheetData, setModifiedApproveTimesheetData,
        selectedPayroll, setSelectedPayroll, selectedPayPoint, setSelectedPayPoint, selectedCostCode, setSelectedCostCode,
        availablePayrolls, setAvailablePayrolls, availablePayPoints, setAvailablePayPoints, availableCostCodes, setAvailableCostCodes,
        selectedTimekeeper, setSelectedTimekeeper, selectedTimesheetData, setSelectedTimesheetData, selectedMonth, setSelectedMonth,
        availableReports, setAvailableReports, employeeDefaults, setEmployeeDefaults
      } = useStateContext(); 

  const [importData, setImportData] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(employees[0]);
  const [selectedDate, setSelectedDate] = useState("2024-08-01");
  const [monthList, setMonthList] = useState(generateMonthList(2024, selectedMonth.number));
  const [employeeIndex, setEmployeeIndex] = useState(0);
  const [disablePreviousButton, setDisablePreviousButton] = useState(true);
  const [disableNextButton, setDisableNextButton] = useState(false);

  const refPopup = useRef(null);
  const refFailedPopup = useRef(null);

  useEffect(() => {
    const fetchLeaveValues = async () => {
      setIsLoading(true);

      if(employees.length === 0){
        const data = await getTimesheetData(user);
        let emps = data.employees;
        const timesheet_data = data.timesheet_data;
        let parm_codes = data.parm_codes;
        parm_codes = parm_codes.filter(code => code.currency);
        const timesheet_settings = data.timesheet_settings;
        const day_types = data.day_types;
        const jobs_fetched = data.jobs;
        const timesheet_approvers = data.timesheet_approvers;
        const payroll_users = data.payroll_users;
        const payroll_rights = data.payroll_rights;
        const available_payrolls = data.available_payrolls;
        const available_paypoints = data.available_paypoints;
        const available_costcodes = data.available_costcodes;
        const all_payrolls = data.all_payrolls;
        const all_paypoints = data.all_paypoints;
        const all_costcodes = data.all_costcodes;
        const available_reports = data.available_reports;
        const employee_defaults = data.employee_defaults;

        setEmployeeDefaults(employee_defaults);

        setAvailableReports(available_reports);
        setSelectedTimekeeper(payroll_users[0]);
        // setSelectedTimesheetData(timesheet_data);
        setInitialEmployees(emps);

        setAllPayrolls(all_payrolls);
        setAllPayPoints(all_paypoints);
        setAllCostCodes(all_costcodes);        
        setSelectedPayroll(available_payrolls[0]);
        setSelectedPayPoint(available_paypoints[0]);
        setSelectedCostCode(available_costcodes[0]);
        setAvailablePayrolls(available_payrolls);
        setAvailablePayPoints(available_paypoints);
        setAvailableCostCodes(available_costcodes);
        setPayrollRights(payroll_rights);        
        setPayrollUsers(payroll_users);          
        setTimesheetApprovers(timesheet_approvers);
        setTimesheetData(timesheet_data);
        setParmCodes(parm_codes);
        setTimesheetSettings(timesheet_settings);
        setDayTypes(day_types);
        setJobs(jobs_fetched);
        
        // monthList = generateMonthList(2024, selectedMonth.number);
        emps = (available_payrolls[0] === "ALL") ? emps : emps.filter((emp) => emp.Payroll === available_payrolls[0]);
        emps = (available_paypoints[0] === "ALL") ? emps : emps.filter((emp) => emp.PayPoint === available_paypoints[0]);
        emps = (available_costcodes[0] === "ALL") ? emps : emps.filter((emp) => emp.CostCodes === available_costcodes[0]);
        setEmployees(emps);

        let availableEmpNos = [];
        emps.forEach(employee => availableEmpNos.push(employee.EmpNo));

        const initial_timesheet_data = timesheet_data.filter(data => (parseInt(data.captured_by) === parseInt(payroll_users[0].id)) & (availableEmpNos.includes(data.EmpNo)));
        setSelectedTimesheetData(initial_timesheet_data);
        const initialCombinedApprovalData = await buildInitialCombinedApprovalData(monthList, initial_timesheet_data, timesheet_settings);
        setCombinedApprovalData(initialCombinedApprovalData);
      } else {
        // setInitialEmployees(employees);

        let newEmployees = initialEmployees;
        newEmployees = (availablePayrolls[0] === "ALL") ? (selectedPayroll === "ALL" ? newEmployees : newEmployees.filter((emp) => emp.Payroll === selectedPayroll)) : newEmployees.filter((emp) => emp.Payroll === selectedPayroll);
        newEmployees = (availablePayPoints[0] === "ALL") ? (selectedPayPoint === "ALL" ? newEmployees : newEmployees.filter((emp) => emp.PayPoint === selectedPayPoint)) : newEmployees.filter((emp) => emp.PayPoint === selectedPayPoint);
        newEmployees = (availableCostCodes[0] === "ALL") ? (selectedCostCode === "ALL" ? newEmployees : newEmployees.filter((emp) => emp.CostCodes === selectedCostCode)) : newEmployees.filter((emp) => emp.CostCodes === selectedCostCode);
        setEmployees(newEmployees);

        let availableEmpNos = [];
        newEmployees.forEach(employee => availableEmpNos.push(employee.EmpNo));
  
        const timesheet_data = timesheetData.filter(data => (parseInt(data.captured_by) === parseInt(selectedTimekeeper.id)) & (availableEmpNos.includes(data.EmpNo)));
        setSelectedTimesheetData(timesheet_data);
        
        const initialCombinedApprovalData = await buildInitialCombinedApprovalData(monthList, timesheet_data, timesheetSettings);
        setCombinedApprovalData(initialCombinedApprovalData);
      }


      setIsLoading(false);
      // setIsInitialLoading(false);
    };
    
    // initialEvents = fetchLeaveValues();
    fetchLeaveValues();
  }, []);

  useEffect(() => {
    console.log("CAD");
  }, [combinedApprovalData]);


  const handleRetry = () => {
    setOpenFailedPopup(false);
    setOpenLeaveApplicationPopup(true);
  };

  const handleFailedPopupClick = (button) => {
    if (button === "retry"){
      handleRetry();
    }
    else if (button === "OK"){
      setOpenFailedPopup(false);

      window.location.reload();
    }
  };

  const replaceKeys = (obj, mapping) => {
    return Object.keys(obj).reduce((acc, key) => {
      const newKey = mapping[key] || key;
      acc[newKey] = ["yes", "Yes", 1, "1", true, "True", "true"].includes(obj[key]) ? 1 : ["no", "No", 0, "0", false, "False", "false"].includes(obj[key]) ? 0 : obj[key];
      return acc;
    }, {});
  };

  const convertFloatToTime = (time) => {
    time = parseFloat(time) * 24;
    const integerPart = Math.floor(time);
    const decimalPart = time - integerPart;

    const hour = integerPart.toString().padStart(2, '0');
    const minute = Math.round(decimalPart * 60).toString().padStart(2, '0');

    return `${hour}:${minute}`;
  };

  const handleXLFileUpload = async (e, editMethod, setRows, leave_setting) => {
    setIsLoading(true);
    const file = e.target.files[0];
    const reader = new FileReader();
    let newList = [];

    reader.onload = (event) => {
      const arrayBuffer = event.target.result;
      const workbook = XLSX.read(new Uint8Array(arrayBuffer), { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      setImportData(jsonData);

      console.log(jsonData);
      // console.log(leaveTypes);
      
      // if (leave_setting === "leave_groups"){
      //   const keyMapping = {}
  
      //   Object.entries(jsonData[0]).forEach(([key, value]) => {
      //     keyMapping[key] = leaveTypes.find(item => item.name === key)?.id || "id";
      //   });
  
      //   // console.log(keyMapping);
  
      //   newList = jsonData.map(item => replaceKeys(item, keyMapping));
  
      //   console.log("1", newList);
      // } else if (leave_setting === "leave_types") {        
      //   let keyMapping = {}

      //   Object.entries(newList[0]).forEach(([key, value]) => {
      //     keyMapping[key] = key.toLowerCase().replace(/ /g, "_");
      //   });

      //   keyMapping = {
      //     ...keyMapping,
      //     "Leave Type": "name",
      //     "Approval Type": "leave_approvers_type"
      //   }
  
      //   newList = jsonData.map(item => ({ 
      //     ...item, 
      //     public_holidays: JSON.stringify(publicHolidays.reduce((acc, holiday) => {
      //       acc[holiday.name] = item[holiday.name];
      //       return acc;
      //     }, {})),
      //     // public_holidays: JSON.stringify({ ...addPublicHolidaysDictPart })
      //   }));

      //   console.log("0", newList);


      //   // console.log(keyMapping);        
        
      //   // newList = newList.map(item => replaceKeys(item, keyMapping));

      //   console.log("1.5", newList);

      //   newList = newList.map(item => {
      //     item.start_time = convertFloatToTime(item.start_time);
      //     item.end_time = convertFloatToTime(item.end_time);
      //     return item;
      //   });

      //   console.log("1", newList);
      // }
    };

    reader.readAsArrayBuffer(file);
    // Wait for the onload event to finish
    await new Promise(resolve => {
      reader.onloadend = resolve;
    });

    const uploadedList = {
      editor_employee_number: user.employee_number,
      rows: newList
    }
    console.log("2", uploadedList);

    const response = await editMethod(uploadedList);
    setRows(response.data.data);
    console.log(response);

    setSnackbar({ children: response.data.message, severity: 'success' });
    setIsLoading(false);
  };

  const handleUploadClick = async (variables) => {
    const fileInput = document.getElementById(`${variables["upload_type"]}-file-input`);
    fileInput.value = null; // Reset the value of the file input

    if (variables["upload_type"] === "xl"){
      fileInput.onchange = (event) => {
        handleXLFileUpload(event, variables["editMethod"], variables["setRows"], variables["leave_setting"]);
      };

    }

    fileInput.click(); // Open the file picker dialog
  };

  const handleSelectMonth = async (event) => {
    setSelectedMonth(event);
    const newMonthList = generateMonthList(2024, event.number);
    setMonthList(newMonthList);
    setSelectedDate(`2024-${event.number.toString().padStart(2, '0')}-01`);
    
    const newData = await buildInitialCombinedApprovalData(newMonthList, timesheetData, timesheetSettings);
    setCombinedApprovalData(newData);
  };


  const handleViewClick = async (row) => {
    // console.log(row.day);
    const newMonthList = generateMonthList(2024, (row.day).split("-")[1]);
    const modifiedViewData = await buildModifiedTimesheetData("approve", [newMonthList.find(item => item.date === row.day), newMonthList.find(item => item.date === row.day)], selectedTimesheetData, employees, timesheetSettings, dayTypes, jobs, employeeDefaults);
    setModifiedApproveTimesheetData(modifiedViewData);

    setOpenLeaveApplicationPopup(true);
    setSelectedDate(row.day);
  }  

  const handleSelectChange = async (value, date) => {
    setIsLoading(true);
    // console.log(value);
    // console.log(event.target.value);
    // console.log(date);
    // return;

    // console.log(modifiedApproveTimesheetData);

    const data = selectedTimesheetData.filter(data => data.date === date);
    // change all data approval_status to approved
    data.forEach((dict) => {
      // console.log(dict["approval_status"]);
      dict["approval_status"] = modifiedApproveTimesheetData.find(item => item.EmpNo === dict.EmpNo).action_to_send;
      // dict["approval_status"] = dict["approval_status"] !== "approved" ? dict["approval_status"] : value;
    });

    // console.log(data);

    const timesheet_data = {
      timesheet_data: data,
      payroll:  selectedPayroll,
      paypoint: selectedPayPoint,
      costcode: selectedCostCode,
    }

    // console.log(timesheet_data);
    console.log(selectedActivity);

    const response = await actionTimesheet(timesheet_data);

    console.log(response);

    let availableEmpNos = [];
    employees.forEach(employee => {
      availableEmpNos.push(employee.EmpNo);
    });

    const filtered_timesheet_data = response.data.timesheet_data.filter(data => (parseInt(data.captured_by) === parseInt(selectedTimekeeper.id)) & (availableEmpNos.includes(data.EmpNo)));

    setTimesheetData(response.data.timesheet_data);
    // console.log(newData);

    //rebuilding combinedTimesheetData
    // console.log(monthList);
    const initialCombinedApprovalData = await buildInitialCombinedApprovalData(monthList, filtered_timesheet_data, timesheetSettings);
    console.log(selectedActivity);

    // console.log(initialCombinedApprovalData);

    setCombinedApprovalData(initialCombinedApprovalData);

    setSnackbar({ children: response.data.message, severity: 'success' });

    console.log(selectedActivity);

    setOpenLeaveApplicationPopup(false);
    setIsLoading(false);
    console.log(selectedActivity);

  };


  const ButtonStyledSelect = styled(MuiSelect)(({ theme }) => ({
    // '&.MuiInputBase-root': {
    //   borderRadius: theme.shape.borderRadius,
    //   backgroundColor: theme.palette.primary.main,
    //   color: theme.palette.primary.contrastText,
    //   padding: theme.spacing(1, 2),
    //   '&:hover': {
    //     backgroundColor: theme.palette.primary.dark,
    //   },
    //   '& .MuiSelect-icon': {
    //     color: theme.palette.primary.contrastText,
    //   },
    // },
  }));


  if (isLoading || employees.length === 0 || !combinedApprovalData.length === 0) {
    return null;
    // return <CircularProgress />;
  }

  // setSelectedEmployee(1);
  // console.log(selectedEmployee);
  // console.log(employees);

  let availableEmpNos = [];
    employees.forEach(employee => {
      availableEmpNos.push(employee.EmpNo);
  });

  let allJobs = [];
    jobs.forEach(code => {
      allJobs.push(`${code.name}`);
  });

  let allDayTypes = [];
    dayTypes.forEach(code => {
      allDayTypes.push(`${code.name}`);
  });

  // let allPayrollUsers = [];
  //   payrollUsers.forEach(code => {
  //     allPayrollUsers.push(`${code.id}`);
  // });

  // APPROVAL GRID


  // setCombinedApprovalData(combinedApprovalDataInitial);

  // console.log(combinedApprovalData);
  // return;

  //APPROVAL TIMESHEET
  const timesheetColumns = [
    { field: 'day', headerName: 'Day', width: 90 },
  ].concat([
    { field: 'total_days', headerName: 'Total Days', width: 80, type: 'float' },
    { field: 'total_amount', headerName: 'Total Amount', width: 100, type: 'float' },
    { field: 'view', headerName: 'View', width: 100, 
      renderCell: ({ row }) => (
      // renderCell: ({ value, id, api }) => (
        <Button 
          disabled={ !["approved", "rejected", "pending"].includes(row.action) ? true : false }
          onClick={() => handleViewClick(row)} 
          type="button" 
          color="secondary" 
          variant="contained"
        >
          View
        </Button>
      )
    },
    { field: 'total_approved', headerName: 'Total Approved', width: 100, type: 'string', editable: false },
    // { field: 'action', headerName: 'Action', width: 100,
    //   renderCell: ({ value, id, api, row }) => (
    //     <MuiSelect
    //       labelId="action-select-label"
    //       id="action-select"
    //       value={value}
    //       label="Action"
    //       onChange={(event) => handleSelectChange(event.target.value, row.day)}
    //     >
    //       <MenuItem value={""}></MenuItem>
    //       <MenuItem value={"pending"}>Pending</MenuItem>
    //       <MenuItem value={"approved"}>Approve All</MenuItem>
    //       <MenuItem value={"rejected"}>Reject All</MenuItem>
    //     </MuiSelect>
    //   )
    //  },
]);

  const addApprovalDict = { };

  // VIEW TIMESHEET GRID
  // DAY VIEW - MODIFIED TIMESHEET GRID

  //MODIFIED TIMESHEET COLUMNS
  const viewColumns = [
    { field: 'EmpNo', headerName: 'EmpNo', width: 60, type: 'singleSelect', valueOptions: availableEmpNos, editable: true },
    { field: 'name', headerName: 'Name', width: 125 },
    { field: 'day_type_id', headerName: 'Day Type', width: 150, 
      type: 'singleSelect',
      valueOptions: allDayTypes,
      valueGetter: (params) => {
        return dayTypes.find(day_type => day_type.id === params.row.day_type_id)?.name;
      },
      valueSetter: (params) => {
        let value_id = dayTypes.find(day_type => day_type.name === params.value)?.id;
        value_id = parseInt(value_id);
        return { ...params.row, day_type_id: value_id};
      },
      editable: true
    },
    { field: 'job_id', headerName: 'Job', width: 150, 
      type: 'singleSelect',
      valueOptions: allJobs,
      valueGetter: (params) => {
        // console.log(params);
        return jobs.find(job => job.id === params.row.job_id)?.name;
      },
      valueSetter: (params) => {
        // console.log(params);
        let value_id = jobs.find(job => job.name === params.value)?.id;
        value_id = parseInt(value_id);
        return { ...params.row, job_id: value_id};
      },
      editable: true
    },
    { field: 'value', headerName: 'Value', width: 60, type: 'float', editable: true },
    { field: 'rate', headerName: 'Rate', width: 80, type: 'float', editable: true },
    { field: 'amount', headerName: 'Amount', width: 100, type: 'float', editable: true },
    { field: 'action_to_send', headerName: 'Action to Send', width: 100, type: 'singleSelect', valueOptions: ["approved", "rejected", "pending"], editable: true },
    { field: 'approval_status', headerName: 'Current Status', width: 100, type: 'singleSelect', valueOptions: ["approved", "rejected", "pending"], editable: true },
    { field: 'actioned_by', headerName: 'Actioned by', width: 80 },
  ];    


  const addViewColumns = { };

  // console.log(modifiedApproveTimesheetData);


  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      {/* // :DROP DOWNS TO SELECT MONTH, TIMEKEEPER, PAYROLL, PAYPOINT, COSTCODE */}
      <DataFilter month={true} timekeeper={true} payroll={true} paypoint={true} costcode={true} activity={true}/>

      <Box sx={{ mt: 2 }} />
      
      <input id="xl-file-input" type="file" accept=".xlsx" style={{ display: 'none' }} /> 

      <CollapsableCard 
        cardHeader={`${(selectedMonth.name).toUpperCase()} TIMESHEET`}
        // cardHeader={`${(selectedMonth.name).toUpperCase()} TIMESHEET - ${(selectedEmployee.name).toUpperCase()} ${(selectedEmployee.surname).toUpperCase()}`}
        expanded={true}
        cardContent={
          <CustomDataGrid 
          rows={combinedApprovalData} 
          setRows={setCombinedApprovalData} 
          otherColumns={[timesheetColumns, addApprovalDict]} 
          backendCRUD = {{
            "C": "",
            "R": "",
            "U": "",
            "D": "",
            "TYPE": "LEAVE TYPE",
          }}
          actions={true}
          extraButtons={[
            {icon: <AddIcon />, label: "Import", func: handleUploadClick, func_variables: {upload_type:"xl", leave_setting: "leave_types"}, func_type: "import excel"},
          ]}
        />
        }
      />

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

      


      <div ref={refPopup}>
        {openLeaveApplicationPopup && 
          <Popup 
            openPopup = {openLeaveApplicationPopup}
            setOpenPopup = {setOpenLeaveApplicationPopup}
          >
            <CollapsableCard 
              cardHeader={`${selectedDate} - TIMESHEET`}
              // cardHeader={`${selectedDay} ${(selectedMonth.name).toUpperCase()} TIMESHEET`}
              expanded={true}
              cardContent={
                <CustomDataGrid 
                rows={modifiedApproveTimesheetData} 
                setRows={setModifiedApproveTimesheetData} 
                otherColumns={[viewColumns, addApprovalDict]} 
                backendCRUD = {{
                  "C": "",
                  "R": "",
                  "U": "",
                  "D": "",
                  "TYPE": "TIMESHEET",
                }}
                actions={true}
                extraButtons={[
                  {icon: <AddIcon />, label: "Import", func: handleUploadClick, func_variables: {upload_type:"xl", leave_setting: "leave_types"}, func_type: "import excel"},
                ]}
              />
              }
            />

            <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

            {/* cancel button left aligned, decline button center aligned, approve button right aligned */}
            <Box display="flex" justifyContent="space-between" width="100%" gap="10px" mt="20px">
                <Button onClick={() => setOpenLeaveApplicationPopup(false)} type="button" color="secondary" variant="contained">
                    Cancel
                </Button>
                <Button onClick={() => handleSelectChange("rejected", selectedDate)} type="button" color="secondary" variant="contained">
                {/* <Button onClick={handleDecline} type="button" color="secondary" variant="contained"> */}
                    Reject All
                </Button>
                <Button onClick={() => handleSelectChange("approved", selectedDate)} type="button" color="secondary" variant="contained">
                    Action
                </Button>
            </Box>
          </Popup>
        }
      </div>

      <div ref={refFailedPopup}>
        {openFailedPopup && 
          <Popup 
            openPopup = {openFailedPopup}
            setOpenPopup = {setOpenFailedPopup}
          >
            <Typography style={{ textAlign: 'center' }}>
              Failed 
              <br />
              {/* {console.log(selectedLeave)} */}
              {/* {selectedLeave.event.title} */}
              <br />
              {resultMessage}
            </Typography>
            <Box display="flex" justifyContent="space-between" width="100%" gap="10px" mt="20px">
                <Button onClick={handleRetry} type="button" color="secondary" variant="contained">
                    Retry
                </Button>
                <Button onClick={() => handleFailedPopupClick("OK")} type="button" color="secondary" variant="contained">
                    Ok
                </Button>
            </Box>
          </Popup>
        }
      </div>

      {/* <LoadingOverlay isLoading={isLoading} /> */}

    </Box>
  );  
};






export default ApproveTimesheetsPage;