import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import { useStateContext } from '../../contexts/ContextProvider';
import { generateMonthList, buildModifiedTimesheetData, buildInitialCombinedApprovalData, editEmployeeDefaults } from '../../services/leaveService';
import { set } from 'date-fns';


export default function DataFilter({ month, day, timekeeper, payroll, paypoint, costcode, employee, activity }) {
    const {
        months, availablePayrolls, selectedMonth, setSelectedMonth, selectedStartDay, setSelectedStartDay, selectedEndDay, setSelectedEndDay,
        selectedPayroll, setSelectedPayroll, selectedPayPoint, setSelectedPayPoint, selectedCostCode, setSelectedCostCode,
        availablePayPoints, availableCostCodes, employees, initialEmployees, setEmployees, timesheetData, timesheetSettings, 
        dayTypes, jobs, setModifiedTimesheetData, setSnackbar, monthList, setMonthList, payrollUsers, selectedTimekeeper, 
        dayIndex, setDayIndex, disablePreviousButton, setDisablePreviousButton, disableNextButton, setDisableNextButton, employeeDefaults,
        setSelectedTimekeeper, setCombinedApprovalData, setSelectedTimesheetData, selectedEmployee, setSelectedEmployee, selectedActivity, setSelectedActivity
    } = useStateContext();

    // const [, forceRerender] = useReducer(x => x + 1, 0);
    
    useEffect(() => {
        const newMonthList = generateMonthList(2024, selectedMonth.number);
        setMonthList(newMonthList);
    }, []);

    console.log(selectedActivity);

    const handleSelectMonth = async (newValue) => {
        setSelectedMonth(newValue);
        const newMonthList = generateMonthList(2024, newValue.number);
        setMonthList(newMonthList);
        setSelectedStartDay(newMonthList[0]);
        setSelectedEndDay(newMonthList[0]);
        const newData = await buildModifiedTimesheetData("timesheet", [newMonthList[0], newMonthList[0]], timesheetData, employees, timesheetSettings, dayTypes, jobs, employeeDefaults);
        setModifiedTimesheetData(newData);

        setDayIndex(0);    
        setDisablePreviousButton(true);
        setDisableNextButton(false);

        //APPROVE TIMESHEETS PAGE
        let availableEmpNos = [];
        employees.forEach(employee => {
          availableEmpNos.push(employee.EmpNo);
        });

        const filtered_timesheet_data = timesheetData.filter(data => (parseInt(data.captured_by) === parseInt(selectedTimekeeper.id)) & (availableEmpNos.includes(data.EmpNo)));
        setSelectedTimesheetData(filtered_timesheet_data);
        const newwData = await buildInitialCombinedApprovalData(newMonthList, filtered_timesheet_data, timesheetSettings);
        setCombinedApprovalData(newwData);
      }
    
    const handleSelectDay = async (newValue, startOrEnd) => {
        let newData = [];
        if (startOrEnd === "start"){
            setSelectedStartDay(newValue);
            
            const startDate = new Date(newValue.date);
            const endDate = new Date(selectedEndDay.date);
            if (startDate > endDate) {
                // const newEndDay = new Date(Math.max(startDate, endDate));
                setSelectedEndDay(newValue);
                newData = await buildModifiedTimesheetData("timesheet", [newValue, newValue], timesheetData, employees, timesheetSettings, dayTypes, jobs, employeeDefaults);
            } else {
                newData = await buildModifiedTimesheetData("timesheet", [newValue, selectedEndDay], timesheetData, employees, timesheetSettings, dayTypes, jobs, employeeDefaults);
            }
        } else {
            setSelectedEndDay(newValue);
            
            const startDate = new Date(selectedStartDay.date);
            const endDate = new Date(newValue.date);
            if (startDate > endDate) {
                // const newStartDay = new Date(Math.max(startDate, endDate));
                setSelectedStartDay(newValue);
                newData = await buildModifiedTimesheetData("timesheet", [newValue, newValue], timesheetData, employees, timesheetSettings, dayTypes, jobs, employeeDefaults);
            } else {
                newData = await buildModifiedTimesheetData("timesheet", [selectedStartDay, newValue], timesheetData, employees, timesheetSettings, dayTypes, jobs, employeeDefaults);
            }
        }

        setModifiedTimesheetData(newData);

        const index = monthList.findIndex((day) => day.date === newValue.date);
        setDayIndex(index);    
        if (index === 0) { setDisablePreviousButton(true);
        } else { setDisablePreviousButton(false); }
    
        if (index >= monthList.length - 1) { setDisableNextButton(true);
        } else { setDisableNextButton(false); }

    }

    const handleSelectAutoComplete = async (type, newValue) => {
        let availableEmpNos = [];
        let newEmployees = initialEmployees;
        let timesheet_data = [];
    
        if (type === "payroll") {
          setSelectedPayroll(newValue);
    
          newEmployees = (availablePayrolls[0] === "ALL") ? (newValue === "ALL" ? newEmployees : newEmployees.filter((emp) => emp.Payroll === newValue)) : newEmployees.filter((emp) => emp.Payroll === newValue);
          newEmployees = (availablePayPoints[0] === "ALL") ? (selectedPayPoint === "ALL" ? newEmployees : newEmployees.filter((emp) => emp.PayPoint === selectedPayPoint)) : newEmployees.filter((emp) => emp.PayPoint === selectedPayPoint);
          newEmployees = (availableCostCodes[0] === "ALL") ? (selectedCostCode === "ALL" ? newEmployees : newEmployees.filter((emp) => emp.CostCodes === selectedCostCode)) : newEmployees.filter((emp) => emp.CostCodes === selectedCostCode);
        } else if (type === "paypoint") {
          setSelectedPayPoint(newValue);
    
          newEmployees = (availablePayrolls[0] === "ALL") ? (selectedPayroll === "ALL" ? newEmployees : newEmployees.filter((emp) => emp.Payroll === selectedPayroll)) : newEmployees.filter((emp) => emp.Payroll === selectedPayroll);
          newEmployees = (availablePayPoints[0] === "ALL") ? (newValue === "ALL" ? newEmployees : newEmployees.filter((emp) => emp.PayPoint === newValue)) : newEmployees.filter((emp) => emp.PayPoint === newValue);
          newEmployees = (availableCostCodes[0] === "ALL") ? (selectedCostCode === "ALL" ? newEmployees : newEmployees.filter((emp) => emp.CostCodes === selectedCostCode)) : newEmployees.filter((emp) => emp.CostCodes === selectedCostCode);
        } else if (type === "costcode") {
          setSelectedCostCode(newValue);
    
          newEmployees = (availablePayrolls[0] === "ALL") ? (selectedPayroll === "ALL" ? newEmployees : newEmployees.filter((emp) => emp.Payroll === selectedPayroll)) : newEmployees.filter((emp) => emp.Payroll === selectedPayroll);
          newEmployees = (availablePayPoints[0] === "ALL") ? (selectedPayPoint === "ALL" ? newEmployees : newEmployees.filter((emp) => emp.PayPoint === selectedPayPoint)) : newEmployees.filter((emp) => emp.PayPoint === selectedPayPoint);
          newEmployees = (availableCostCodes[0] === "ALL") ? (newValue === "ALL" ? newEmployees : newEmployees.filter((emp) => emp.CostCodes === newValue)) : newEmployees.filter((emp) => emp.CostCodes === newValue);
        } else if (type === "employee") {
            setSelectedEmployee(newValue);
      
            newEmployees = (availablePayrolls[0] === "ALL") ? (selectedPayroll === "ALL" ? newEmployees : newEmployees.filter((emp) => emp.Payroll === selectedPayroll)) : newEmployees.filter((emp) => emp.Payroll === selectedPayroll);
            newEmployees = (availablePayPoints[0] === "ALL") ? (selectedPayPoint === "ALL" ? newEmployees : newEmployees.filter((emp) => emp.PayPoint === selectedPayPoint)) : newEmployees.filter((emp) => emp.PayPoint === selectedPayPoint);
            newEmployees = (availableCostCodes[0] === "ALL") ? (selectedCostCode === "ALL" ? newEmployees : newEmployees.filter((emp) => emp.CostCodes === selectedCostCode)) : newEmployees.filter((emp) => emp.CostCodes === selectedCostCode);
        } else if (type === "activity") {
            setSelectedActivity(newValue);
      
            newEmployees = (availablePayrolls[0] === "ALL") ? (selectedPayroll === "ALL" ? newEmployees : newEmployees.filter((emp) => emp.Payroll === selectedPayroll)) : newEmployees.filter((emp) => emp.Payroll === selectedPayroll);
            newEmployees = (availablePayPoints[0] === "ALL") ? (selectedPayPoint === "ALL" ? newEmployees : newEmployees.filter((emp) => emp.PayPoint === selectedPayPoint)) : newEmployees.filter((emp) => emp.PayPoint === selectedPayPoint);
            newEmployees = (availableCostCodes[0] === "ALL") ? (selectedCostCode === "ALL" ? newEmployees : newEmployees.filter((emp) => emp.CostCodes === selectedCostCode)) : newEmployees.filter((emp) => emp.CostCodes === selectedCostCode);

            if (newValue === "ALL"){
                newEmployees.forEach(employee => {
                    availableEmpNos.push(employee.EmpNo);
                });
            } else {
                let empNosWithActivity = [];
                employeeDefaults.filter(emp => emp.job_id === newValue.id).forEach(emp => empNosWithActivity.push(emp.EmpNo));
                newEmployees = newEmployees.filter(emp => empNosWithActivity.includes(emp.EmpNo));

                newEmployees.forEach(employee => availableEmpNos.push(employee.EmpNo));
                
                // timesheet_data = timesheetData.filter(data => (parseInt(data.job_id) === parseInt(newValue.id)) & (availableEmpNos.includes(data.EmpNo)));
            }
            timesheet_data = timesheetData.filter(data => (parseInt(data.captured_by) === parseInt(selectedTimekeeper.id)) & (availableEmpNos.includes(data.EmpNo)));

        } else if (type === "timekeeper") {
            setSelectedTimekeeper(newValue);

            employees.forEach(employee => {
                availableEmpNos.push(employee.EmpNo);
            });
    
            timesheet_data = timesheetData.filter(data => (parseInt(data.captured_by) === parseInt(newValue.id)) & (availableEmpNos.includes(data.EmpNo)));
        }

        setEmployees(newEmployees);

        // IF PAGE HAS TIMEKEEPER FILTER
        if (timekeeper) {
            // IF FILTER CHANGED ON THAT PAGE IS NOT TIMEKEEPER OR ACTIVITY
            if (!["timekeeper", "activity"].includes(type)){
                
                newEmployees.forEach(employee => availableEmpNos.push(employee.EmpNo));
                
                timesheet_data = timesheetData.filter(data => (parseInt(data.captured_by) === parseInt(selectedTimekeeper.id)) & (availableEmpNos.includes(data.EmpNo)));
            }

            //THIS DATA WILL BE USED IN THE POP UP ON APPROVE TIMESHEETS PAGE (SO FAR CONFIRMED ONLY)
            setSelectedTimesheetData(timesheet_data);
    
            const initialCombinedApprovalData = await buildInitialCombinedApprovalData(monthList, timesheet_data, timesheetSettings);
            setCombinedApprovalData(initialCombinedApprovalData);
        }
        else {           

            const newData = await buildModifiedTimesheetData("timesheet", [selectedStartDay, selectedEndDay], timesheetData, newEmployees, timesheetSettings, dayTypes, jobs, employeeDefaults);
            setModifiedTimesheetData(newData);
        }        


      }

    if (monthList.length === 0) return null;

    return (
        <Box>
            {/* // :DROP DOWNS TO SELECT MONTH, DAY, PAYROLL, PAYPOINT, COSTCODE */}
            <Box sx={{ mt: 2 }} display="flex" justifyContent="space-between" width="90%" gap="10px" mt="20px">
                {/* // :DROP DOWN TO SELECT MONTH */}
                {month && (
                    <Autocomplete
                        // fit 25% on the side
                        sx={{ width: '25%' }}
                        options={months}
                        getOptionLabel={(month) => month.name }
                        renderInput={(params) => (
                            <TextField {...params} label="Select Month" variant="outlined" />
                        )}
                        value={selectedMonth}
                        onChange={(event, newValue) => {
                            if (newValue !== null) { 
                                handleSelectMonth(newValue);
                            }

                        }}
                        isOptionEqualToValue={(month, value) => month.number === value.number}
                    />
                )}

                {/* // :DROP DOWN TO SELECT DAY*/}
                {day && (
                    <Box display="flex" justifyContent="space-between" gap="10px" width="40%">
                        <Autocomplete
                            // fit half available width
                            sx={{ width: '50%' }} 
                            options={monthList}
                            getOptionLabel={(day) => (`${day["day_name"]} - ${day["day"]}`) }
                            renderInput={(params) => (
                                <TextField {...params} label="Select Start Day" variant="outlined" />
                            )}
                            value={selectedStartDay}
                            onChange={(event, newValue) => {
                                if (newValue !== null) { 
                                    handleSelectDay(newValue, "start");
                                }
                            }}
                            isOptionEqualToValue={(day, value) => day.day === value.day}
                        />
                        <Autocomplete
                            // fit half available width
                            sx={{ width: '50%' }} 
                            options={monthList}
                            getOptionLabel={(day) => (`${day["day_name"]} - ${day["day"]}`) }
                            renderInput={(params) => (
                                <TextField {...params} label="Select End Day" variant="outlined" />
                            )}
                            value={selectedEndDay}
                            onChange={(event, newValue) => {
                                if (newValue !== null) { 
                                    handleSelectDay(newValue, "end");
                                }
                            }}
                            isOptionEqualToValue={(day, value) => day.day === value.day}
                        />
                    </Box>
                )}

                {/* // :DROP DOWN TO SELECT TIMEKEEPER*/}
                {timekeeper && (
                    <Autocomplete
                        // fit quarter width
                        sx={{ width: '25%' }} 
                        options={payrollUsers}
                        getOptionLabel={(timekeeper) => (`${timekeeper["id"]} - ${timekeeper["name"]} ${timekeeper["surname"]}`) }
                        renderInput={(params) => (
                            <TextField {...params} label="Select Timekeeper" variant="outlined" />
                        )}
                        value={selectedTimekeeper}
                        onChange={(event, newValue) => {
                            if (newValue !== null) { 
                            handleSelectAutoComplete("timekeeper", newValue);
                            }
                        }}
                        isOptionEqualToValue={(timekeeper, value) => timekeeper.id === value.id}
                    />
                )}


                {/* // :DROP DOWN TO SELECT PAYROLL */}
                {payroll && (
                    <Autocomplete
                        // fit quarter width
                        sx={{ width: '25%' }} 
                        // options={["ALL"].concat(availablePayrolls)}
                        options={availablePayrolls}
                        // getOptionLabel={(day) => (`${day["day_name"]} - ${day["day"]}`) }
                        renderInput={(params) => (
                            <TextField {...params} label="Select Payroll" variant="outlined" />
                        )}
                        value={selectedPayroll}
                        onChange={(event, newValue) => {
                            if (newValue !== null) { 
                            handleSelectAutoComplete("payroll", newValue);
                            }
                        }}
                        isOptionEqualToValue={(day, value) => day.day === value.day}
                    />
                )}

                {/* // :DROP DOWN TO SELECT PAYPOINT */}
                {paypoint &&(
                    <Autocomplete
                        // fit quarter width
                        sx={{ width: '25%' }} 
                        options={availablePayPoints}
                        // getOptionLabel={(day) => (`${day["day_name"]} - ${day["day"]}`) }
                        renderInput={(params) => (
                            <TextField {...params} label="Select PayPoint" variant="outlined" />
                        )}
                        value={selectedPayPoint}
                        onChange={(event, newValue) => {
                            if (newValue !== null) { 
                            handleSelectAutoComplete("paypoint", newValue);
                            }
                        }}
                        isOptionEqualToValue={(day, value) => day.day === value.day}
                    />
                )}

                {/* // :DROP DOWN TO SELECT COSTCODE */}
                {costcode && (
                    <Autocomplete
                        // fit quarter width
                        sx={{ width: '25%' }} 
                        options={availableCostCodes}
                        // getOptionLabel={(day) => (`${day["day_name"]} - ${day["day"]}`) }
                        renderInput={(params) => (
                            <TextField {...params} label="Select CostCode" variant="outlined" />
                        )}
                        value={selectedCostCode}
                        onChange={(event, newValue) => {
                            if (newValue !== null) { 
                            handleSelectAutoComplete("costcode", newValue);
                            }
                        }}
                        isOptionEqualToValue={(day, value) => day.day === value.day}
                    />
                )}

                {/* // :DROP DOWN TO SELECT EMPLOYEE */}
                {employee && (
                    <Autocomplete
                        // fit quarter width
                        sx={{ width: '25%' }} 
                        options={["ALL"].concat(employees)}
                        getOptionLabel={(employee) => employee !== "ALL" ? (`${employee.EmpNo} - ${(employee.GivenNames).toUpperCase()} ${(employee.Surname).toUpperCase()}`) : "ALL" }
                        renderInput={(params) => (
                            <TextField {...params} label="Select Employee" variant="outlined" />
                        )}
                        defaultValue={"ALL"}
                        // value={selectedEmployee}
                        onChange={(event, newValue) => {
                            if (newValue !== null) { 
                                handleSelectAutoComplete("employee", newValue);
                            }
                        }}
                        isOptionEqualToValue={(employee, value) => value !== "ALL" ? (employee.EmpNo === value.EmpNo) : (employee === value)}
                    />
                )}

                {/* // :DROP DOWN TO SELECT ACTIVITY */}
                {activity && (
                    <Autocomplete
                        // fit quarter width
                        sx={{ width: '25%' }} 
                        options={["ALL"].concat(jobs)}
                        getOptionLabel={(job) => job !== "ALL" ? `${job.id || job} - ${(job.name)}` : "ALL"}
                        renderInput={(params) => (
                            <TextField {...params} label="Select Activity" variant="outlined" />
                        )}
                        defaultValue={"ALL"}
                        // defaultValue={selectedActivity ?? "ALL"}
                        onChange={(event, newValue) => {
                            if (newValue !== null) { 
                                handleSelectAutoComplete("activity", newValue);
                            }
                        }}
                        isOptionEqualToValue={(activity, value) => value !== "ALL" ? (activity.id === value.id) : (activity === value)}
                    />
                )}
            </Box>

            <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}
        </Box>
    );
}