import { ApiService, setCsrfAndTokenHeader } from "./axiosConfig";

import format from 'date-fns/format'
import { addDays } from 'date-fns'
import moment from 'moment-timezone';
import { json } from "react-router-dom";


export const addEvent = async (data, values) => {
    // 2024-03-11T13:00:00+02:00
    // console.log(values);
    const selected = data["selected"];
    const leave_id = data["leave_id"];
    const title = values.leave_types.find(item => item.id === values.selected_leave_type)?.name

    const calendarApi = selected.view.calendar;
    calendarApi.unselect();

    calendarApi.addEvent({
        editable: true,
        id: leave_id,
        title,
        start: values.pickedDateInitial,
        end: values.pickedDateFinal,
        allDay: selected.allDay,
        status: "pending",
        leave_duration: data["duration"],
        action_reason: "",
        leave_type_id: values["selected_leave_type"],
        application_reason: values["application_reason"],
        actioned_by: "",
        attachment: data["attachment"],
    });

    // console.log(Object.keys(calendarApi))
    // console.log(calendarApi.getEvents());

    let result = {"success" : true, "message": "leave successfully applied"};
    return result;
}

export const editEvent = async (data, values) => {
    const selected = data["selected"];
    // const calendarApi = selected.view.calendar;
    // calendarApi.unselect();

    selected.calendaApiEventToUpdate.setAllDay(data["allDay"]);
    selected.calendaApiEventToUpdate.setStart(values.pickedDateInitial);
    selected.calendaApiEventToUpdate.setEnd(values.pickedDateFinal);

    // console.log("SELECTED", selected);
    // console.log("DATA", data);
    // console.log("VALUES", values);
    // return;

    // const eventToUpdate = calendarApi.getEventById(selectedEditLeave.id);
    // eventToUpdate.setStart(selectedEditLeave.startStr+'T00:00:00');
    // eventToUpdate.setEnd(selectedEditLeave.endStr+'T00:00:00');
    // calendarApi.updateEvent(eventToUpdate);

    // calendarApi.updateEvent({
    //     id: leave_id,
    //     title,
    //     start: values.pickedDateInitial,
    //     end: values.pickedDateFinal,
    //     allDay: selected.allDay,
    //     status: "pending",
    //     leave_duration: data["duration"],
    //     action_reason: "",
    //     leave_type_id: values["selected_leave_type"],
    //     application_reason: values["application_reason"],
    //     actioned_by: "",
    //     attachment: data["attachment"],
    // });

    // calendarApi.updateEvent(eventToUpdate);

    // console.log(calendarApi.getEvents());

    let result = {"success" : true, "message": "leave successfully edited"};
    return result;
}

const formatLeaveTakenForFullCalendar = (leave_taken) => {
    const formated_employee_leave_taken = [];
    for (let i = 0; i < leave_taken.length; i++) {
        const endStr = (leave_taken[i].end_date.split("T")).length > 1 ? leave_taken[i].end_date : format(addDays(new Date(leave_taken[i].end_date), 1), "yyyy-MM-dd")

        formated_employee_leave_taken.push(
            {
                editable: leave_taken[i].status !== "pending" ? false : true,
                id: leave_taken[i].id,
                title: leave_taken[i].title,
                start: leave_taken[i].start_date,
                end: endStr,
                status: leave_taken[i].status,
                leave_duration: leave_taken[i].duration,
                action_reason: leave_taken[i].action_reason,
                leave_type_id: leave_taken[i].leave_type_id,
                application_reason: leave_taken[i].application_reason,
                actioned_by: leave_taken[i].actioned_by,
                attachment: leave_taken[i].attachment,
            });
    }

    return formated_employee_leave_taken;
}

export const applyLeave = async (initial_data, values) => {  
    try {
        // console.log(values);
        const duration = initial_data["duration"];

        const available_balance = values.leave_balances[values.selected_leave_type]["available"]
        // const pending_balance  = values.leave_balances.find(item => item.leave_type === values.selected_leave_type)?.pending
        // const initial_leave_balance = available_balance + pending_balance;
    
        if ((available_balance - duration) < 0){
            let result = {"status" : false, 
                          "data" : {"message": "Not enough leave days left"}};
            return result;
        }

        //SEND TO BACKEND.

        const formData = new FormData();
        formData.append("employee_number", initial_data.employee_number);
        formData.append("leave_type_id", values.selected_leave_type);
        formData.append("start_date", values.pickedDateInitial);
        formData.append("end_date", values.pickedDateFinal);
        formData.append("duration", initial_data.duration);
        formData.append("application_reason", values.application_reason);
        formData.append("attachment", initial_data.attachment);
        formData.append("status", "pending");

        // console.log("FORMDATA", formData);

        await setCsrfAndTokenHeader("post")
        const registerResponse = await ApiService.post('/apply-leave', formData);

        console.log("applyResponse", registerResponse);

        const formated_employee_leave_taken = formatLeaveTakenForFullCalendar(registerResponse["data"]["employee_leave_taken"]);

        // const transformedResultsData = changeDatesInsideResultsFromStringFormat(registerResponse.data.employee_leave_taken);

        // const transformedRegisterResponse = { ...registerResponse, data: { ...registerResponse.data, employee_leave_taken: transformedResultsData } }
        const transformedRegisterResponse = { ...registerResponse, data: { ...registerResponse.data, employee_leave_taken: formated_employee_leave_taken } }

        return transformedRegisterResponse
        // return registerResponse

    } catch (error) {
        console.error("applyLeave - ERROR TRY", error);
        return error.response
    }
};

export const editLeave = async (initial_data) => {  
    try {
        const data = {
            "id" : initial_data["edited_leave_id"],
            "leave_type_id" : initial_data["selected_leave_type"],
            "start_date" : initial_data["edited_leave_startStr"],
            "end_date" : initial_data["edited_leave_endStr"],
            "duration" : initial_data["duration"],
            "application_reason" : initial_data["application_reason"],
            "attachement" : initial_data["attachement"],
            "status" : "pending"
        };

        console.log("editLeave - DATA", data);

        // return;

        await setCsrfAndTokenHeader("put")
        const editResponse = await ApiService.put('/edit-leave', data);

        console.log("editRESPONSE", editResponse);

        const formated_employee_leave_taken = formatLeaveTakenForFullCalendar(editResponse["data"]["employee_leave_taken"]);

        // const transformedResultsData = changeDatesInsideResultsFromStringFormat(editResponse.data.employee_leave_taken);
        // const transformedEditResponse = { ...editResponse, data: { ...editResponse.employee_leave_taken, employee_leave_taken: transformedResultsData } }
        
        const transformedEditResponse = { ...editResponse, data: { ...editResponse.data, employee_leave_taken: formated_employee_leave_taken } }

        console.log("transformedEditResponse", transformedEditResponse);
        return transformedEditResponse
        // return editResponse

    } catch (error) {
        console.error("editLeave - ERROR TRY", error);
        return error.response
    }
};

export const getLeaveTypes = async () => {
    try {
        await setCsrfAndTokenHeader("get")
        const response = await ApiService.get('/leave-types');
        
        console.log("getLeaveTypes", response);
        return response.data.results;
        // setUSerData(result.data.results)
    } catch (err) {
        console.log("somthing Wrong leave types");
    }
};

export const addLeaveType = async (initial_data) => {    
    try {
        console.log("GRID - addLeaveType", initial_data);

        await setCsrfAndTokenHeader("post")
        const editResponse = await ApiService.post('/add-leave-type', initial_data);

        return editResponse

    } catch (error) {
        console.error("addLeaveType - ERROR TRY", error);
        return error.response
    }
};

export const editLeaveType = async (initial_data) => {    
    try {
        console.log("GRID - editLeaveType", initial_data);

        // initial_data.rows[0].public_holidays = Object.keys(JSON.parse(initial_data.rows[0].public_holidays)).reduce((acc, key) => {
        //     acc[key] = ["yes", "Yes", 1, "1", true, "True", "true"].includes(initial_data.rows[0][key]) ? 1 : 0;
        //     return acc;
        // }, {});

        initial_data.rows = initial_data.rows.map(item => {
            item.public_holidays = Object.keys(JSON.parse(item.public_holidays)).reduce((acc, key) => {
                acc[key] = ["yes", "Yes", 1, "1", true, "True", "true"].includes(item[key]) ? 1 : 0;
                return acc;
            }, {});
            return item;
        });
        
        console.log("DATA - editLeaveType", initial_data);

        await setCsrfAndTokenHeader("put")
        const editResponse = await ApiService.put('/edit-leave-type', initial_data);
        
        const edited_leave_types = editResponse.data.data.map(leave_type => {
            return {
                ...leave_type,
                ...JSON.parse(leave_type.public_holidays)
            }
        })
        
        const finalEditResponse = {
            ...editResponse,
            data: {
                ...editResponse.data,
                data: [
                    ...edited_leave_types
                ]
            }
        };
                

        console.log(finalEditResponse);
        return finalEditResponse

    } catch (error) {
        console.error("editLeaveType - ERROR TRY", error);
        return error.response
    }
};

export const deleteLeaveType = async (leave_id) => {
    try {
        const data = {
            "leave_taken_id" : leave_id,
        };

        await setCsrfAndTokenHeader("delete")
        const response = await ApiService.delete('/delete-leave', {data: data,});

        console.log("deleteLeaveTaken");

        return response

    } catch (error) {
        console.error("deleteLeaveTaken - ERROR TRY", error);
        return error.response;
    }
}

export const getEmployeeLeaveBalances = async (user) => {
    // console.log("EMPLOYEE LS", user)
    try {
        await setCsrfAndTokenHeader("get")
        const response = await ApiService.get('/get-user-leave-balances/'+user.employee_number);
        
        let balances = response.data.results.balances;
        balances = JSON.parse(balances);
        console.log("getEmployeeLeaveBalances", response);

        return balances;
        // setUSerData(result.data.results)
    } catch (err) {
        // console.log(err);
        console.log("somthing Wrong get Balances");
    }
}

export const getLeaveBalances = async () => {
    try {
        await setCsrfAndTokenHeader("get")
        const response = await ApiService.get('/leave-balances/');

        console.log("getLeaveBalances", response);

        return response.data.results;
    } catch (err) {
        // console.log(err);
        console.log("somthing Wrong get Balances");
    }
}

export const addLeaveBalance = async (initial_data) => {    
    console.log("IDDD", initial_data); 
    const modified_data = {rows:[]};
    modified_data["rows"] = initial_data["rows"].map((in_data) => {
        
        const balancesDict = {};
        
        for (const key in in_data["balances"]) {
            if (in_data.hasOwnProperty(key)) {
                balancesDict[key] = in_data[key] ? { available: in_data[key], pending: 0 } : { available: null };
            }
        }
        
        const updated_data = { ...in_data, "balances": balancesDict };

        return updated_data;
    });

    try {
        console.log("GRID - addLeaveBalance", modified_data);

        await setCsrfAndTokenHeader("post")
        const editResponse = await ApiService.post('/set-leave-balances', modified_data);
        
        console.log("editResponse", editResponse);
        // console.log("editRESPONSE", editResponse.data);
        return editResponse

    } catch (error) {
        console.error("addLeaveBalance - ERROR TRY", error);
        return error.response
    }
};

export const editLeaveBalance = async (initial_data) => {    
    try {
        console.log("GRID - editLeaveBalance", initial_data);

        await setCsrfAndTokenHeader("put")
        const editResponse = await ApiService.put('/setLeaveBalances', initial_data);

        // console.log("editRESPONSE", editResponse.data);
        return editResponse

    } catch (error) {
        console.error("editLeaveBalance - ERROR TRY", error);
        return error.response
    }
};

export const addPublicHoliday = async (initial_data) => {    
    try {
        console.log("GRID - addPublicHoliday", initial_data);

        const data = {...initial_data};
        data["rows"][0]["date"] = format(data["rows"][0]["date"], "yyyy-MM-dd")

        await setCsrfAndTokenHeader("post")
        const addResponse = await ApiService.post('/add-public-holiday', data);
        
        console.log("addResponse", addResponse);
        // console.log("addRESPONSE", addResponse.data);
        return addResponse

    } catch (error) {
        console.error("addPublicHoliday - ERROR TRY", error);
        return error.response
    }
};

export const editPublicHoliday = async (initial_data) => {    
    try {
        console.log("GRID - editPublicHoliday", initial_data);

        const data = {...initial_data};
        data["rows"][0]["date"] = format(data["rows"][0]["date"], "yyyy-MM-dd")

        await setCsrfAndTokenHeader("put")
        const editResponse = await ApiService.put('/edit-public-holiday', data);

        // console.log("editRESPONSE", editResponse.data);
        return editResponse

    } catch (error) {
        console.error("editPublicHoliday - ERROR TRY", error);
        return error.response
    }
};

export const deleteLeaveRecord = async (id, to_delete) => {
    console.log(id, to_delete);
    try {
        const data = {
            "id" : id,
            "to_delete" : to_delete
        };

        console.log("deleteLeaveRecord", data);

        await setCsrfAndTokenHeader("delete")
        let response = await ApiService.delete('/delete-leave-record', {data: data,});
        
        console.log("deleteResponse", response);

        if (to_delete === "LEAVE TAKEN"){
            const formated_employee_leave_taken = formatLeaveTakenForFullCalendar(response["data"]["employee_leave_taken"]);
    
            response = {...response, "data": {...response["data"], "employee_leave_taken": formated_employee_leave_taken}};
        }

        return response

    } catch (error) {
        console.error("deleteLeaveRecord - ERROR TRY", error);
        return error.response;
    }
}

export const deleteLeaveTaken = async (id, to_delete) => {
    console.log(id, to_delete);
    try {
        const data = {
            "id" : id,
            "to_delete" : to_delete
        };

        console.log("deleteLeaveTaken", data);

        await setCsrfAndTokenHeader("delete")
        let response = await ApiService.delete('/delete-leave-taken', {data: data,});
        
        console.log("deleteResponse", response);

        if (to_delete === "LEAVE TAKEN"){
            const formated_employee_leave_taken = formatLeaveTakenForFullCalendar(response["data"]["employee_leave_taken"]);
    
            response = {...response, "data": {...response["data"], "employee_leave_taken": formated_employee_leave_taken}};
        }

        return response

    } catch (error) {
        console.error("deleteLeaveTaken - ERROR TRY", error);
        return error.response;
    }
}

export const getLeaveTaken = async (user, initialEvents) => {
    // console.log("GLT", user)
    try {
        await setCsrfAndTokenHeader("post")
        const response = await ApiService.post('/leave-taken/'+user.employee_number);

        let leave_taken = await response.data.results;
        // leave_taken = JSON.parse(leave_taken);
        // console.log("GLT LT", leave_taken);
        console.log("getLeaveTaken", response);


        if(!initialEvents.length){
            initialEvents = formatLeaveTakenForFullCalendar(leave_taken);
        }
        // console.log("IEEEE", initialEvents);

        return initialEvents;
        // setUSerData(result.data.results)
    } catch (err) {
        console.log(err);
        console.log("somthing Wrong get Leave Taken");
    }
}

export const getApproveeLeaveTaken = async (user, initialEvents) => {
    // console.log("GLT", user)
    try {
        await setCsrfAndTokenHeader("get")
        const response = await ApiService.get('/approvee-leave-taken/'+user.employee_number);

        const leave_taken = Object.values(response.data.results);
        // leave_taken = JSON.parse(leave_taken);
        // console.log("GLT LT", leave_taken);
        console.log("getApproveeLeaveTaken", response);

        const responseResults = changeDatesInsideResultsFromStringFormat(leave_taken);
    
        // if(!initialEvents.length){
        //     for (let i = 0; i < leave_taken.length; i++) {
        //         initialEvents.push(
        //             {
        //                 id: leave_taken[i].id,
        //                 title: leave_taken[i].leave_type_id,
        //                 start: leave_taken[i].start_date,
        //                 end: format(addDays(new Date(leave_taken[i].end_date), 1), "yyyy-MM-dd"),
        //                 status: leave_taken[i].status,
        //             });
        //     }
        // }
        // console.log("IEEEE", initialEvents);

        return responseResults;
        // setUSerData(result.data.results)
    } catch (err) {
        console.log(err);
        console.log("somthing Wrong get Approvee Leave Taken");
    }
}

export const getLeaveGroups = async () => {
    try {
        await setCsrfAndTokenHeader("get")
        const response = await ApiService.get('/leave-groups/');

        console.log("getLeaveGroups", response);

        // const leave_groups = response.data.leave_groups;
        // const leave_types = response.data.leave_types;

        // let leave_type_ids = [];

        // leave_types.forEach((leave_type) => {
        //     leave_type_ids.push(leave_type["id"]);
        // });

        // const leave_groups_modified = leave_groups.map((group) => {
        //     const result = { ...group };
        
        //     leave_type_ids.forEach((id) => {
        //     result[id] = group["leave_type_ids"].includes(id) ? 1 : 0;
        //     });

        //     return result;
        // });

        return response.data.results;
        // return leave_groups_modified;
    } catch (err) {
        // console.log(err);
        console.log("somthing Wrong get Groups");
    }
}

export const addLeaveGroup = async (initial_data) => {    
    const modified_data = {rows:[]};
        modified_data["rows"] = initial_data["rows"].map((data) => {
            const numberKeys = Object.entries(data)
                                        .filter(([key, value]) => !['id', "leave_type_ids", "created_at", "updated_at", "isNew"].includes(key) && ![0, "", false].includes(value))
                                        .map(([key]) => key);
            
            const updated_data = { ...data, "leave_type_ids": numberKeys };

            return updated_data;
    })

    try {
        console.log("GRID - addLeaveGroup", modified_data);

        await setCsrfAndTokenHeader("post")
        const editResponse = await ApiService.post('/add-leave-group', modified_data);

        // console.log("editResponse", editResponse);
        // console.log("editRESPONSE", editResponse.data);
        return editResponse

    } catch (error) {
        console.error("addLeaveGroup - ERROR TRY", error);
        return error.response
    }
};

export const editLeaveGroup = async (initial_data) => {    
    console.log("INITIAL - editLeaveGroup", initial_data);
    const modified_data = {rows:[]};
    modified_data["rows"] = initial_data["rows"].map((data) => {
        const numberKeys = Object.entries(data)
                                    .filter(([key, value]) => !['id', "leave_type_ids", "created_at", "updated_at", "isNew"].includes(key) && ![0, "", false].includes(value))
                                    .map(([key]) => key);
        
        const updated_data = { ...data, "leave_type_ids": numberKeys };

        return updated_data;
    })

    try {
        console.log("GRID - editLeaveGroup", modified_data);

        await setCsrfAndTokenHeader("put")
        const editResponse = await ApiService.put('/edit-leave-group', modified_data);

        // console.log("editRESPONSE", editResponse.data);
        return editResponse

    } catch (error) {
        console.error("editLeaveGroup - ERROR TRY", error);
        return error.response
    }
};

export const editEmployee = async (initial_data) => {    
    try {
        console.log("GRID - editEmployee", initial_data);

        await setCsrfAndTokenHeader("put")
        const editResponse = await ApiService.put('/edit-user', initial_data);

        // console.log("editRESPONSE", editResponse.data);
        return editResponse

    } catch (error) {
        console.error("editEmployee - ERROR TRY", error);
        return error.response
    }
};

const changeDatesInsideResultsFromStringFormat = (results) => {
    const transformedData = results.map((dict) => {
        // Create a new object to avoid mutation
        const updatedDict = {};

        const formateDate = (key) => {
            let date;
            if(key === "start_date"){
                date = dict.start_date;
            } else {
                date = dict.end_date;
            }
            
            // console.log("START SPLIT", start_date.split("T"));
            // console.log("END SPLIT", end_date.split("T"));
            
            if(date.split("T").length === 1){
                date = new Date(date);

                const timezone_offset = (new Date().getTimezoneOffset()) / 60

                date = new Date(date.getTime() + (timezone_offset * 60 * 60 * 1000));
            } else {
                date = new Date(date);
            }

            // console.log("START DATE", start_date);
            // console.log("END DATE", end_date);
            return date;
        }

        // Iterate over each key-value pair
        for (const [key, value] of Object.entries(dict)) {
            updatedDict[key] = ['start_date', 'end_date'].includes(key) ? formateDate(key) : value;
            // updatedDict[key] = ['start_date', 'end_date'].includes(key) ? new Date(value) : value;
        }

        return updatedDict;
    });

    return transformedData;
}

export const getAllLeaveTaken = async () => {
    try {
        await setCsrfAndTokenHeader("get")
        const response = await ApiService.get('/all-leave-taken/');

        console.log("getAllLeaveTaken", response);

        const responseDataResults = changeDatesInsideResultsFromStringFormat(response.data.results);
        
        return responseDataResults;
        // return response.data.results;
    } catch (err) {
        // console.log(err);
        console.log("somthing Wrong get AllLeaveTaken");
    }
};

export const actionLeaveTaken = async (initial_data) => {    
    try {
        console.log("GRID - actionLeave", initial_data);

        let startStr = format(initial_data.rows[0].start_date, "yyyy-MM-dd'T'HH:mm:ss");
        let endStr = format(initial_data.rows[0].end_date, "yyyy-MM-dd'T'HH:mm:ss");

        if(startStr.split("T")[1] === "00:00:00" && endStr.split("T")[1] === "23:59:59"){
            startStr = startStr.split("T")[0];
            endStr = endStr.split("T")[0];
        }

        const data = {
            ...initial_data,
            rows: {
                ...initial_data.rows,
                0: {
                    ...initial_data.rows[0],
                    start_date: startStr,
                    end_date: endStr
                }
            },
        }

        await setCsrfAndTokenHeader("put")
        const editResponse = await ApiService.put('/action-leave-taken', data);

        console.log("editRESPONSE", editResponse);

        const formated_employee_leave_taken = formatLeaveTakenForFullCalendar(editResponse["data"]["employee_leave_taken"]);
        const formated_approvee_leave_taken = changeDatesInsideResultsFromStringFormat(editResponse["data"]["approvee_leave_taken"]);
        // const formated_approvee_leave_taken = formatLeaveTakenForFullCalendar(editResponse["data"]["approvee_leave_taken"]);

        // const transformedResultsData = changeDatesInsideResultsFromStringFormat(Object.values(editResponse.data.data));
        // const transformedEditResponse = { ...editResponse, data: { ...editResponse.data, data: transformedResultsData } }
        
        const transformedEditResponse = {
            ...editResponse, 
            data: {
                ...editResponse.data, 
                employee_leave_taken: formated_employee_leave_taken,
                approvee_leave_taken: formated_approvee_leave_taken,
            } 
        }

        return transformedEditResponse
        // return editResponse

    } catch (error) {
        console.error("actionLeave - ERROR TRY", error);
        return error.response
    }
};

export const getAccrualRuns = async () => {
    try {
        await setCsrfAndTokenHeader("get")
        const response = await ApiService.get('/accrual-runs');

        console.log("getAccrualRuns", response);
        return response.data.results;
        // setUSerData(result.data.results)
    } catch (err) {
        console.log("somthing Wrong Accrual Runs");
    }
};

export const getLeavePlannerData = async (user, initialEvents) => {
    try {
        await setCsrfAndTokenHeader("get")
        const response = await ApiService.get('/leave-planner-data/'+user.employee_number);

        // debugger
        // console.log("REE", response);
        // debugger

        //LEAVE TAKEN
        let leave_taken = await response.data.leave_taken;

        // debugger
        // console.log("LTT", leave_taken);
        // debugger

        if(!initialEvents.length){
            initialEvents = formatLeaveTakenForFullCalendar(leave_taken);
        }

        // debugger
        // console.log("FLT", initialEvents);
        // debugger

        //LEAVE TYPES
        const edited_leave_types = response.data.leave_types.map(leave_type => {
            return {
                ...leave_type,
                ...JSON.parse(leave_type.public_holidays)
            }
        });

        // debugger
        // console.log("ELT", initialEvents);
        // debugger

        const leavePlannerResponse = { 
            ...response.data,
            leave_taken: initialEvents,
            leave_types: edited_leave_types,
        };
        // debugger
        console.log("getLeavePlannerData", leavePlannerResponse);
        // debugger

        return leavePlannerResponse;
        // setUSerData(result.data.results)
    } catch (err) {
        console.log("somthing Wrong Leave Planner Data");
    }
};

export const runAccrualRun = async (initial_data) => {    
    try {
        console.log("accrualRun", initial_data);

        await setCsrfAndTokenHeader("post")
        const editResponse = await ApiService.post('/accrual-run', initial_data);

        // console.log("editRESPONSE", editResponse.data);
        return editResponse

    } catch (error) {
        console.error("accrualRun - ERROR TRY", error);
        return error.response
    }
};


const parseTimeToMinutes = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  };

// Calculate duration in minutes
const calculateDuration = (startTime, endTime) => {
    return parseTimeToMinutes(endTime) - parseTimeToMinutes(startTime);
};

// Calculate the fraction
const calculateFraction = (leave_type_times, selected_times) => {
    const backendDuration = calculateDuration(leave_type_times["start_time"], leave_type_times["end_time"]);
    let userDuration = 0;
    const userStartTimeInMinutes = parseTimeToMinutes(selected_times["start_time"]);
    const userEndTimeInMinutes = parseTimeToMinutes(selected_times["end_time"]);

    // if start_time is greater that end_time
    if (userStartTimeInMinutes > userEndTimeInMinutes) {
        const firstDayDuration = calculateDuration(selected_times["start_time"], leave_type_times["end_time"]);
        const lastDayDuration = calculateDuration(leave_type_times["start_time"], selected_times["end_time"]);
        userDuration = firstDayDuration + lastDayDuration;
    } else {
        userDuration = calculateDuration(selected_times["start_time"], selected_times["end_time"]);
    }

    return (userStartTimeInMinutes <= userEndTimeInMinutes) ? (userDuration / backendDuration) : ((userDuration / backendDuration) - 1);
};

export const workDuration = (range, publicHolidays, selectedLeaveType, leaveTypes) => {
    const start_date = format(range[0].startDate, "yyyy-MM-dd");
    const end_date = format(range[0].endDate, "yyyy-MM-dd");
    const excludedDays = [];
    let new_end_date = "";

    const filteredData = leaveTypes.filter((item) => item.id === selectedLeaveType);

    // console.log("Filtered Data", filteredData);
    // console.log("Public Holidays", publicHolidays);
    
    if((filteredData[0].sunday === 0) || (filteredData[0].sunday === null)){excludedDays.push(0);}  
    if((filteredData[0].monday === 0) || (filteredData[0].monday === null)){excludedDays.push(1);}  
    if((filteredData[0].tuesday === 0) || (filteredData[0].tuesday === null)){excludedDays.push(2);}  
    if((filteredData[0].wednesday === 0) || (filteredData[0].wednesday === null)){excludedDays.push(3);}  
    if((filteredData[0].thursday === 0) || (filteredData[0].thursday === null)){excludedDays.push(4);}  
    if((filteredData[0].friday === 0) || (filteredData[0].friday === null)){excludedDays.push(5);}  
    if((filteredData[0].saturday === 0) || (filteredData[0].saturday === null)){excludedDays.push(6);}

    Object.keys(JSON.parse(filteredData[0].public_holidays)).forEach((key) => {
        if(filteredData[0][key] === 0){
            excludedDays.push(publicHolidays.find((holiday) => holiday.name === key)?.date);
        }
    });

    const getNumberOfDays = (startDate, endDate) => {
        let currentDate = new Date(startDate);
        const targetDate = new Date(endDate);
        let numberOfDays = 0;
      
        while (currentDate <= targetDate) {
            // console.log("CURRENT DATE", currentDate);
            const string_current_date = format(currentDate, "yyyy-MM-dd");

            if (!excludedDays.includes(currentDate.getDay()) && !excludedDays.includes(string_current_date)) { // Tuesday has index 2
                numberOfDays++;

                //check if currentDate is the last countable day
                new_end_date = string_current_date;
            }

            currentDate.setDate(currentDate.getDate() + 1);
        }

        //if manual, calculate the fraction of the day from the given start_time and end_time based on the leaveType's start_time and end_time
        if(range[0].manual_duration_enabled){
            let fraction_of_day = 0;
            if(excludedDays.includes(targetDate.getDay())){
                //set end time to end of day
                fraction_of_day = calculateFraction(range[0].leave_type_times, {
                    start_time: range[0].selected_times.start_time, 
                    end_time: range[0].leave_type_times.end_time
                });
            } else{
                fraction_of_day = calculateFraction(range[0].leave_type_times, range[0].selected_times);
            }
        
            numberOfDays = numberOfDays - 1 + fraction_of_day;
            return numberOfDays > 0 ? parseFloat(numberOfDays.toFixed(2)) : 0;
        }

        return numberOfDays;
    }

    // return getNumberOfDays(start_date, end_date);
    return {
        duration: getNumberOfDays(start_date, end_date),
        new_end_date: new_end_date === end_date ? null : new_end_date
    }
    
}

// Maintanance Mode
export const getMaintenanceMode = async () => {
    try {
        await setCsrfAndTokenHeader("get")
        const response = await ApiService.get('/get-maintenance');
        
        console.log("getMaintenanceMode", response);
        return response.data.maintenance_mode;
        // setUSerData(result.data.results)
    } catch (err) {
        console.log("somthing Maintanance Mode");
    }
};

export const toggleMaintenanceMode = async () => {    
    try {
        await setCsrfAndTokenHeader("post")
        const editResponse = await ApiService.post('/toggle-maintenance', {});
        console.log("toggleMaintenanceMode", editResponse);

        return editResponse

    } catch (error) {
        console.error("toggleMaintenanceMode", error);
        return error.response
    }
};



export const editDayTypes = async (initial_data) => {
    console.log("initial_data", initial_data);
    try {
        console.log("GRID - editDayTypes", initial_data);

        await setCsrfAndTokenHeader("put")
        const editResponse = await ApiService.put('/edit-day-types', initial_data);

        console.log("editRESPONSE", editResponse);

        return editResponse

    } catch (error) {
        console.error("editDayTypes - ERROR TRY", error);
        return error.response
    }
};

export const editJobs = async (initial_data) => {
    console.log("initial_data", initial_data);
    try {
        console.log("GRID - editJobs", initial_data);

        await setCsrfAndTokenHeader("put")
        const editResponse = await ApiService.put('/edit-jobs', initial_data);

        console.log("editRESPONSE", editResponse);

        return editResponse

    } catch (error) {
        console.error("editJobs - ERROR TRY", error);
        return error.response
    }
};

export const editTimesheetSettings = async (initial_data) => {
    console.log("initial_data", initial_data);
    try {
        console.log("GRID - editTimesheet", initial_data);

        await setCsrfAndTokenHeader("put")
        const editResponse = await ApiService.put('/edit-timesheet-settings', initial_data);

        console.log("editRESPONSE", editResponse);

        return editResponse

    } catch (error) {
        console.error("editTimesheet - ERROR TRY", error);
        return error.response
    }
};

export const editTimesheetApprovers = async (initial_data) => {
    console.log("initial_data", initial_data);
    try {
        console.log("GRID - editTimesheetApprovers", initial_data);

        await setCsrfAndTokenHeader("put")
        const editResponse = await ApiService.put('/edit-timesheet-approvers', initial_data);

        console.log("editRESPONSE", editResponse);

        return editResponse

    } catch (error) {
        console.error("editTimesheetApprovers - ERROR TRY", error);
        return error.response
    }
};

export const editPayrollUsers = async (initial_data) => {
    console.log("initial_data", initial_data);
    try {
        console.log("GRID - editPayrollUsers", initial_data);

        await setCsrfAndTokenHeader("put")
        const editResponse = await ApiService.put('/edit-payroll-users', initial_data);

        console.log("editRESPONSE", editResponse);

        return editResponse

    } catch (error) {
        console.error("editPayrollUsers - ERROR TRY", error);
        return error.response
    }
};

export const editPayrollRights = async (initial_data) => {
    console.log("initial_data", initial_data);
    try {
        console.log("GRID - editPayrollRights", initial_data);

        await setCsrfAndTokenHeader("put")
        const editResponse = await ApiService.put('/edit-payroll-rights', initial_data);

        console.log("editRESPONSE", editResponse);

        return editResponse

    } catch (error) {
        console.error("editPayrollRights - ERROR TRY", error);
        return error.response
    }
};

export const editEmployeeDefaults = async (initial_data) => {
    console.log("initial_data", initial_data);
    try {
        console.log("GRID - editEmployeeDefaults", initial_data);

        await setCsrfAndTokenHeader("put")
        const editResponse = await ApiService.put('/edit-employee-default', initial_data);

        console.log("editRESPONSE", editResponse);

        return editResponse

    } catch (error) {
        console.error("editEmployeeDefaults - ERROR TRY", error);
        return error.response
    }
};

export const actionTimesheet = async (initial_data) => {
    try {
        console.log("GRID - actionTimesheet", initial_data);

        await setCsrfAndTokenHeader("put")
        const editResponse = await ApiService.put('/action-timesheet', initial_data);

        console.log("editRESPONSE", editResponse);

        return editResponse

    } catch (error) {
        console.error("actionTimesheet - ERROR TRY", error);
        return error.response
    }
};

export const getTimesheetData = async (user) => {
    try {
        await setCsrfAndTokenHeader("get")
        // const response = await ApiService.get('/timesheet-data/'+user.id);
        const num = 5;
        const response = await ApiService.get('/timesheet-data/'+num);

        console.log("getTimesheetData", response);

        return response.data;
    } catch (err) {
        console.log(err);
        console.log("somthing Wrong get Timesheet Data");
    }
}

export const sendTimesheetForApproval = async (timesheetData) => {
    try {
        await setCsrfAndTokenHeader("post")
        const editResponse = await ApiService.post('/send-timesheet-for-approval', timesheetData);
        console.log("sendTimesheetForApproval", editResponse);

        return editResponse

    } catch (error) {
        console.error("sendTimesheetForApproval", error);
        return error.response
    }
};

export const saveUploadedTimesheet = async (timesheetData) => {
    try {
        await setCsrfAndTokenHeader("post")
        const editResponse = await ApiService.post('/save-uploaded-timesheet', timesheetData);
        console.log("sendTimesheetForApproval", editResponse);

        return editResponse

    } catch (error) {
        console.error("sendTimesheetForApproval", error);
        return error.response
    }
};

export const generateMonthList = (year, month) => {
    const monthList = [];
    const date = new Date(year, month - 1, 1);
  
    while (date.getMonth() === month - 1) {
        // Create a new date object to avoid modifying the original date
        const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
        
        monthList.push({
            day_name: localDate.toLocaleDateString('en-US', { weekday: 'short' }),
            date: localDate.toISOString().split('T')[0],
            day: localDate.getDate()
        });
        date.setDate(date.getDate() + 1);
    }
    
    return monthList;
  };
  
export const buildInitialCombinedApprovalData = (monthList, timesheetData, timesheetSettings) => {
    let id = 0;
    const combinedApprovalDataInitial = monthList.map((day) => {
        let columns = {};
        let all_days_total = 0;
        let all_amounts_total = 0;
        let approval_status = "";

        const value_data = timesheetData.filter(data => (data.date === day["date"]));
        const data_length = value_data.length;
        let total_approved = 0;
        // const value_data = timesheetData.filter(data => (data.date === day["date"]) & (data.captured_by === timekeeper.id));
        let total = 0;
        let amount_total = 0;
        value_data.forEach(data => {
            total += parseFloat(data.value) || 0;

            const rate = parseFloat(timesheetSettings.find(setting => (parseInt(setting.day_type_id) === parseInt(data.day_type_id)) & (parseInt(setting.job_id) === parseInt(data.job_id)))?.rate)
            amount_total = total * rate;

            approval_status = data.approval_status;
            total_approved += data.approval_status === "approved" ? 1 : 0;
        });

        all_days_total += total;
        all_amounts_total += amount_total;

        id += 1;

        return { 
            id: id,
            day: day["date"],
            total_days: all_days_total,
            total_amount: `$${(Math.round(all_amounts_total * 100) / 100).toFixed(2)}`,
            total_approved: `${total_approved} / ${data_length}`,
            action: approval_status
        };
    });

    return combinedApprovalDataInitial;
}

export const buildModifiedTimesheetData = async (page, newDates, timesheetData, employees, timesheetSettings, dayTypes, jobs, employeeDefaults) => {
    let id = 0;

    // console.log(timesheetData);
    // console.log(employees);

    // console.log(selectedDate);
    // const dateFilteredTimesheetData = timesheetData.filter(data => data.date === newDates[0].date);

    const startDate = new Date(newDates[0].date);
    const endDate = new Date(newDates[1].date);
    const dateFilteredTimesheetData = timesheetData.filter(item => {
        const itemDate = new Date(item.date);
        return itemDate >= startDate && itemDate <= endDate;
    });

    //create list of dates between startDate and EndDate in "YYYY-MM-DD" format
    const dates = [];
    while (startDate <= endDate) {
        dates.push(startDate.toISOString().split('T')[0]);
        startDate.setDate(startDate.getDate() + 1);
    }

    let combinedViewData = [];

    employees.forEach((employee) => {
        const filteredTimesheetData = dateFilteredTimesheetData.filter(data => data.EmpNo === employee["EmpNo"]);
        // console.log(filteredTimesheetData);

        if (filteredTimesheetData.length > 0){
            filteredTimesheetData.forEach(data => {
                id += 1;
                const rate = parseFloat(timesheetSettings.find(item => (parseInt(item.day_type_id) === parseInt(data.day_type_id)) & (parseInt(item.job_id) === parseInt(data.job_id)))?.rate);
                // const rate = timesheetSettings.find(item => (item.day_type_id === data.day_type_id) & (item.job_id === data.job_id))?.rate || 0;
                combinedViewData.push({
                    id: id,
                    EmpNo: employee["EmpNo"],
                    name: `${employee["GivenNames"]} ${employee["Surname"]}`,
                    day_type_id: parseInt(data.day_type_id),
                    job_id: parseInt(data.job_id),
                    value: parseFloat(data.value) || "",
                    rate: rate,
                    amount: data.amount,
                    action_to_send: page === "approve" ? (data.approval_status !== "pending" ? data.approval_status : "approved") : data.approval_status,
                    approval_status: data.approval_status,
                    date: data.date,
                    actioned_by: data.actioned_by
                });
            })

            dates.forEach(date => {
                if (!filteredTimesheetData.some(data => data.date === date)) {
                    id += 1;
                    const rate = parseFloat(timesheetSettings.find(item => (parseInt(item.day_type_id) === parseInt(dayTypes[0].id)) & (parseInt(item.job_id) === parseInt(jobs[0].id)))?.rate);
                    combinedViewData.push({
                        id: id,
                        EmpNo: employee["EmpNo"],
                        name: `${employee["GivenNames"]} ${employee["Surname"]}`,
                        day_type_id: dayTypes[0].id || "",
                        job_id: employeeDefaults.find(item => (parseInt(item.EmpNo) === parseInt(employee["EmpNo"])))?.job_id || "",
                        value: "",
                        rate: rate,
                        // rate: timesheetSettings.find(item => (item.day_type_id === dayTypes[0].id) & (item.job_id === jobs[0].id))?.rate || 0,
                        amount: "",
                        approval_status: "",
                        date: date
                    });
                }
            });
        } else {
            dates.forEach(date => {
                id += 1;
                const rate = parseFloat(timesheetSettings.find(item => (parseInt(item.day_type_id) === parseInt(dayTypes[0].id)) & (parseInt(item.job_id) === parseInt(jobs[0].id)))?.rate);
                combinedViewData.push({
                    id: id,
                    EmpNo: employee["EmpNo"],
                    name: `${employee["GivenNames"]} ${employee["Surname"]}`,
                    day_type_id: dayTypes[0].id || "",
                    job_id: employeeDefaults.find(item => (parseInt(item.EmpNo) === parseInt(employee["EmpNo"])))?.job_id || "",
                    value: "",
                    rate: rate,
                    // rate: timesheetSettings.find(item => (item.day_type_id === dayTypes[0].id) & (item.job_id === jobs[0].id))?.rate || 0,
                    amount: "",
                    approval_status: "",
                    date: date
                });
            });
        }
    });

    // console.log(combinedViewData);
    return combinedViewData;
}

export const exportReports = async (initial_data) => {
    try {
        console.log("GRID - exportReports", initial_data);

        await setCsrfAndTokenHeader("post")
        const editResponse = await ApiService.post('/export-reports', initial_data);

        console.log("editRESPONSE", editResponse);

        return editResponse

    } catch (error) {
        console.error("exportReports - ERROR TRY", error);
        return error.response
    }
};

export const deleteTimesheetSetting = async (id, to_delete) => {
    console.log(id, to_delete);
    try {
        const data = {
            "id" : id,
            "to_delete" : to_delete
        };

        console.log("deleteLeaveRecord", data);

        await setCsrfAndTokenHeader("delete")
        let response = await ApiService.delete('/delete-timesheet-setting', {data: data,});
        
        console.log("deleteResponse", response);

        return response

    } catch (error) {
        console.error("deleteLeaveRecord - ERROR TRY", error);
        return error.response;
    }
}

