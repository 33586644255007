import * as React from 'react';
import { useState, useRef, useEffect, useReducer } from 'react';
import { Box, Button, Typography, CircularProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useStateContext } from "../../contexts/ContextProvider";
import { getApproveeLeaveTaken, actionLeaveTaken, getLeavePlannerData, addLeaveType, editLeaveType,
  buildModifiedTimesheetData, getTimesheetData, sendTimesheetForApproval, saveUploadedTimesheet
} from "../../services/leaveService";
import CustomDataGrid from '../../components/CustomDataGrid';
import CollapsableCard from '../../components/CollapsableCard';
import Popup from "../../components/Popup";
import LeaveApprovalForm from "../leave_application_form/leave_approval_form";
import Select from 'react-select';
import moment from 'moment';
import LoadingOverlay from '../global/LoadingOverlay';
import * as XLSX from 'xlsx';
import CustomSnackBar from '../../components/CustomSnackBar';
// import FormControl from '@mui/material';
// import InputLabel from '@mui/material';
// import MenuItem from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { date, number } from 'yup';
import { GridSaveAltIcon } from '@mui/x-data-grid';
import { ErrorSharp, SaveOutlined } from '@mui/icons-material';
import { set } from 'date-fns';
import * as Papa from 'papaparse';
import DataFilter from '../global/DataFilter';
import { generateMonthList } from '../../services/leaveService';


const TimesheetPage = () => {
  let { user, employees, setEmployees, approveeLeaveTaken, setApproveeLeaveTaken, openLeaveApplicationPopup, setOpenLeaveApplicationPopup, 
        openFailedPopup, setOpenFailedPopup, resultMessage, selectedLeave, setSelectedLeave, isLoading, setIsLoading, setSnackbar,
        timesheetData, timesheetSettings, months, setTimesheetData, setTimesheetSettings, setMonths, jobs, setJobs, dayTypes, setDayTypes,
        modifiedTimesheetData, setModifiedTimesheetData, parmCodes, setParmCodes, timesheetApprovers, setTimesheetApprovers, 
        payrollUsers, setPayrollUsers, setSelectedTimekeeper, setSelectedTimesheetData,
        payrollRights, setPayrollRights, initialEmployees, setInitialEmployees, selectedStartDay, setSelectedStartDay, selectedEndDay, setSelectedEndDay,
        allPayrolls, setAllPayrolls, allPayPoints, setAllPayPoints, allCostCodes, setAllCostCodes, monthList,
        selectedPayroll, setSelectedPayroll, selectedPayPoint, setSelectedPayPoint, selectedCostCode, setSelectedCostCode,
        availablePayrolls, setAvailablePayrolls, availablePayPoints, setAvailablePayPoints, availableCostCodes, setAvailableCostCodes, employeeDefaults, setEmployeeDefaults,
        selectedMonth, availableReports, setAvailableReports, dayIndex, setDayIndex, disablePreviousButton, setDisablePreviousButton, disableNextButton, setDisableNextButton
      } = useStateContext(); 

  const [, forceRerender] = useReducer(x => x + 1, 0);

  const [initialLoad, setInitialLoad] = useState(true);
  const [importData, setImportData] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(employees[0]);
  const [selectedTimesheetView, setSelectedTimesheetView] = useState("Day");
  const [excelUploadErrorPopup, setExcelUploadErrorPopup] = useState(false);
  const [excelUploadErrors, setExcelUploadErrors] = useState([]);
  const errors = ["ERRORS"];

  const refErrorPopup = useRef(null);
  const refPopup = useRef(null);
  const refFailedPopup = useRef(null);

  useEffect(() => {
    const fetchLeaveValues = async () => {
      setIsLoading(true);
      
      if(employees.length === 0){
        const data = await getTimesheetData(user);
        let emps = data.employees;
        const timesheet_data = data.timesheet_data;
        let parm_codes = data.parm_codes;
        parm_codes = parm_codes.filter(code => code.currency);
        const timesheet_settings = data.timesheet_settings;
        const day_types = data.day_types;
        const jobs_fetched = data.jobs;
        const timesheet_approvers = data.timesheet_approvers;
        const payroll_users = data.payroll_users;
        const payroll_rights = data.payroll_rights;
        const available_payrolls = data.available_payrolls;
        const available_paypoints = data.available_paypoints;
        const available_costcodes = data.available_costcodes;
        const all_payrolls = data.all_payrolls;
        const all_paypoints = data.all_paypoints;
        const all_costcodes = data.all_costcodes;
        const available_reports = data.available_reports;
        const employee_defaults = data.employee_defaults;

        setEmployeeDefaults(employee_defaults);
        setAvailableReports(available_reports);
        setSelectedTimekeeper(payroll_users[0]);
        setSelectedTimesheetData(timesheet_data);
        setInitialEmployees(emps);

        setAllPayrolls(all_payrolls);
        setAllPayPoints(all_paypoints);
        setAllCostCodes(all_costcodes);        
        setSelectedPayroll(available_payrolls[0]);
        setSelectedPayPoint(available_paypoints[0]);
        setSelectedCostCode(available_costcodes[0]);
        setAvailablePayrolls(available_payrolls);
        setAvailablePayPoints(available_paypoints);
        setAvailableCostCodes(available_costcodes);
        setPayrollRights(payroll_rights);        
        setPayrollUsers(payroll_users);
        setTimesheetApprovers(timesheet_approvers);
        setTimesheetData(timesheet_data);
        setParmCodes(parm_codes);
        setTimesheetSettings(timesheet_settings);
        setDayTypes(day_types);
        setJobs(jobs_fetched);


        emps = (available_payrolls[0] === "ALL") ? emps : emps.filter((emp) => emp.Payroll === available_payrolls[0]);
        emps = (available_paypoints[0] === "ALL") ? emps : emps.filter((emp) => emp.PayPoint === available_paypoints[0]);
        emps = (available_costcodes[0] === "ALL") ? emps : emps.filter((emp) => emp.CostCodes === available_costcodes[0]);

        setEmployees(emps);
        // console.log(emps);

        const initialModifiedTimesheetData = await buildModifiedTimesheetData("timesheet", [selectedStartDay, selectedEndDay], timesheet_data, emps, timesheet_settings, day_types, jobs_fetched, employee_defaults);
        // console.log(initialModifiedTimesheetData);
        setModifiedTimesheetData(initialModifiedTimesheetData);
      } else {
        let newEmployees = initialEmployees;
        newEmployees = (availablePayrolls[0] === "ALL") ? (selectedPayroll === "ALL" ? newEmployees : newEmployees.filter((emp) => emp.Payroll === selectedPayroll)) : newEmployees.filter((emp) => emp.Payroll === selectedPayroll);
        newEmployees = (availablePayPoints[0] === "ALL") ? (selectedPayPoint === "ALL" ? newEmployees : newEmployees.filter((emp) => emp.PayPoint === selectedPayPoint)) : newEmployees.filter((emp) => emp.PayPoint === selectedPayPoint);
        newEmployees = (availableCostCodes[0] === "ALL") ? (selectedCostCode === "ALL" ? newEmployees : newEmployees.filter((emp) => emp.CostCodes === selectedCostCode)) : newEmployees.filter((emp) => emp.CostCodes === selectedCostCode);

        setEmployees(newEmployees);

        const initialModifiedTimesheetData = await buildModifiedTimesheetData("timesheet", [selectedStartDay, selectedEndDay], timesheetData, newEmployees, timesheetSettings, dayTypes, jobs, employeeDefaults);
        // console.log(initialModifiedTimesheetData);
        setModifiedTimesheetData(initialModifiedTimesheetData);
      }

      setIsLoading(false);
    };
    
    fetchLeaveValues();

  }, []);

  // useEffect(() => {
  //   // eslint
  // }, [modifiedTimesheetData]);

  // console.log(initialEmployees);


  const handleViewClick = (row_id) => {
    setSelectedLeave(approveeLeaveTaken.filter((row) => row.id === row_id));
    setOpenLeaveApplicationPopup(true);
  }

  const handleRetry = () => {
    setOpenFailedPopup(false);
    setOpenLeaveApplicationPopup(true);
  };

  const handleFailedPopupClick = (button) => {
    if (button === "retry"){
      handleRetry();
    }
    else if (button === "OK"){
      setOpenFailedPopup(false);

      window.location.reload();
    }
  };

  const handleEmployeeChange = (newEmployee) => {
    setSelectedEmployee(newEmployee);
    const foundIndex = employees.findIndex(dict => dict.employee_number === newEmployee.employee_number);
    setDayIndex(foundIndex);
  };

  const handlePreviousOrNextEmployeeOrDay = async (previous_or_next) => {
    let index = 0;
    if (previous_or_next === "previous day") {
      index = (dayIndex - 1) <= 0 ? 0 : (dayIndex - 1);

      setSelectedStartDay(monthList[index]);
      setSelectedEndDay(monthList[index]);
      const newData = await buildModifiedTimesheetData("timesheet", [monthList[index], monthList[index]], timesheetData, employees, timesheetSettings, dayTypes, jobs, employeeDefaults);
      setModifiedTimesheetData(newData);
      setDayIndex(index);
    } else if (previous_or_next === "next day") {
      // const nextIndex = (dayIndex + 1) % employees.length;
      index = (dayIndex + 1) >= monthList.length ? dayIndex : (dayIndex + 1);

      setSelectedStartDay(monthList[index]);
      setSelectedEndDay(monthList[index]);
      const newData = await buildModifiedTimesheetData("timesheet", [monthList[index], monthList[index]], timesheetData, employees, timesheetSettings, dayTypes, jobs, employeeDefaults);
      setModifiedTimesheetData(newData);
      setDayIndex(index);

    }

    if (index === 0) { setDisablePreviousButton(true);
    } else { setDisablePreviousButton(false); }

    if (index >= monthList.length - 1) { setDisableNextButton(true);
    } else { setDisableNextButton(false); }
  };

  const replaceKeys = (obj, mapping) => {
    return Object.keys(obj).reduce((acc, key) => {
      const newKey = mapping[key] || key;
      acc[newKey] = ["yes", "Yes", 1, "1", true, "True", "true"].includes(obj[key]) ? 1 : ["no", "No", 0, "0", false, "False", "false"].includes(obj[key]) ? 0 : obj[key];
      return acc;
    }, {});
  };

  const convertFloatToTime = (time) => {
    time = parseFloat(time) * 24;
    const integerPart = Math.floor(time);
    const decimalPart = time - integerPart;

    const hour = integerPart.toString().padStart(2, '0');
    const minute = Math.round(decimalPart * 60).toString().padStart(2, '0');

    return `${hour}:${minute}`;
  };

  const convertExcelDate = (serial) => {
    const epoch = new Date(1899, 11, 30);
    const days = parseInt(serial, 10);
  
    const date = new Date(epoch.getTime() + days * 86400000 + 620000); // 86400000 ms per day  + 10mins20seconds for some reason
    const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));

    return localDate.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  };

  // console.log(employees);

  const transformExcelTimesheetData = (data) => {
    // console.log(employees.map(emp => emp.EmpNo));
    console.log(data);

    const transformedData = [];
    let lastName = "";
    let employee_number = "";
    let day_columns = {};
    let id = 1;
    let total_values = {};
    dayTypes.forEach((day) => total_values[day.name] = 0);
    let amount_to_add = 0;
    let total_employees_uploaded = 0;
    const employeesUploaded = [];
    let error_message = "";

    for (let i = 3; i <= 45; i++) {
      //check if integer
      // if (!isNaN(parseInt(data[1][`__EMPTY_${i}`]))) {
      if (Number.isInteger(data[1][`__EMPTY_${i}`])) {
        day_columns[`__EMPTY_${i}`] = convertExcelDate(data[1][`__EMPTY_${i}`]);
      };
    };

    //iterating over each row from row number 3
    for (let i = 2; i < data.length - 1; i++) {
      if (data[i]["__EMPTY_1"]){
        lastName = data[i]["__EMPTY_1"];
        employee_number = parseInt(data[i]["__EMPTY"]);
        
        //Find DUPLICATE. If employee_number in employeesUploaded, push error in errors and continue for loop
        if (employeesUploaded.includes(employee_number)) {
          errors.push(`Employee ${employee_number} - duplicate found`);
          continue;
        } else {
          employeesUploaded.push(employee_number);
          total_employees_uploaded += 1;
        }
      }

      //iterating over each column from column number 4
      for (let j = 3; j <= 45; j++) {
        if (day_columns[`__EMPTY_${j}`]){
          if (data[i][`__EMPTY_${j}`]){
            const EmpNo = employees.find((emp) => (parseInt(emp.EmpNo) === employee_number))?.EmpNo
            // const EmpNo = employees.find((emp) => (emp.GivenNames === lastName.split(' ')[0]) & (emp.Surname === lastName.split(' ')[1]))?.EmpNo
            const day_type_id = parseInt(dayTypes.find((day) => day.name === data[i]["__EMPTY_2"])?.id);
            const job_id = parseInt(employeeDefaults.find((emp) => emp.EmpNo === EmpNo)?.job_id);
            const value = parseFloat(data[i][`__EMPTY_${j}`]);
            const rate = parseFloat(timesheetSettings.find((setting) => (parseInt(setting.day_type_id) === day_type_id) & (parseInt(setting.job_id) === job_id))?.rate);
            const amount = Math.round(value * rate * 100) / 100 || 0;

            //Checking if DayType exists
            if (EmpNo && !day_type_id){
              error_message = `Day type ${data[i]["__EMPTY_2"]} on EmpNo ${EmpNo} not found`;
              if (errors.includes(error_message) === false){
                errors.push(error_message);
              }
            } else if (EmpNo){
              const newRow = {
                id: id,
                EmpNo: EmpNo,
                date: day_columns[`__EMPTY_${j}`],
                day_type_id: day_type_id,
                job_id: job_id,
                value: data[i][`__EMPTY_${j}`],
                amount: amount,
                approval_status: "",
              };

              transformedData.push(newRow);
              id += 1;

              amount_to_add = parseFloat(data[i][`__EMPTY_${j}`])
              
              if (isNaN(amount_to_add)) { 
                console.log(EmpNo);
                console.log(data[i][`__EMPTY_${j}`]);
                amount_to_add = 0; 
              }

              total_values[data[i]["__EMPTY_2"]] += amount_to_add;
              // console.log(EmpNo);
              // console.log(typeof data[i][`__EMPTY_${j}`]);
              // console.log(total_values[data[i]["__EMPTY_2"]]);
              // console.log(" - ");

            } else {
              error_message = `Employee ${employee_number} - ${lastName} not found`;
              if (errors.includes(error_message) === false){
                errors.push(error_message);
              }
            }
          }
        }
      };
    };
    	
    if (errors.length === 1){ errors.push("No errors found") };
    // DUPLICATE EMPLOYEES IN EXCEL
    // DAY TYPE NOT FOUND
    // EMPLOYEE IN EXCEL NOT FOUND IN DB => SHOULD ALREADY BE THERE
    // EMPLOYEE XX TERMINATED IN DB BUT HAS VALUE IN EXCEL => CHECK IF TERMINATED EMPLOYEES ARE EVEN BEING USED
 
    const total_value_uploaded = total_values["Normal Days"] + total_values["Sundays"] + total_values["Holidays"] + total_values["Overtime hours"] + total_values["Overtime other"] + total_values["Off"] + total_values["Sick Leave"] + total_values["Vacation Leave"] + total_values["Compasionate Leave"];
    const days_uploaded = "01-08-2024 to 31-08-2024"

    const excel_value_3 = data.at(-1)[`__EMPTY_${Object.keys(data.at(-1)).length - 9}`];
    const excel_value_10 = data.at(-1)[`__EMPTY_${Object.keys(data.at(-1)).length - 2}`] + excel_value_3;

    const normal_length = `${total_values["Normal Days"]}`.length;
    console.log(normal_length);

    const uploadedColumn = (key) => (`${total_values[key]}`.padEnd(37 + (normal_length - `${total_values[key]}`.length)));
    const excelColumn = (num, key) => (`${data.at(-1)[`__EMPTY_${Object.keys(data.at(-1)).length - num}`]}`.padEnd(40 + (normal_length - `${total_values[key]}`.length)));
    const varianceColumn = (num, key) => (`${total_values[key] - data.at(-1)[`__EMPTY_${Object.keys(data.at(-1)).length - num}`]}`);

    errors.push("-".padEnd(10));
    errors.push("UPLOAD DETAILS");
    errors.push(`Total employees uploaded: ${total_employees_uploaded}`);
    errors.push("-".padEnd(10));
    errors.push("Day Type".padEnd((50 - 8))  +  "UPLOADED".padEnd(30)  +  "EXCEL".padEnd(30)  +  "VARIANCE".padEnd(30));
    errors.push("Normal Days".padEnd((52 - 12))  +  uploadedColumn("Normal Days")  +  excelColumn(11, "Normal Days")  +  varianceColumn(11, "Normal Days"));
    errors.push("Sundays-".padEnd((52 - 8))  +  uploadedColumn("Sundays")  +  excelColumn(10, "Sundays")  +  varianceColumn(10, "Sundays"));
    errors.push("Holidays".padEnd((54 - 9))  +  uploadedColumn("Holidays")  +  excelColumn(9, "Holidays")  +  varianceColumn(9, "Holidays"));
    errors.push("Overtime hours".padEnd((53 - 15))  +  uploadedColumn("Overtime hours")  +  excelColumn(8, "Overtime hours")  +  varianceColumn(8, "Overtime hours"));
    errors.push("Overtime other".padEnd((53 - 15))  +  uploadedColumn("Overtime other")  +  excelColumn(7, "Overtime other")  +  varianceColumn(7, "Overtime other"));
    errors.push("Off".padEnd((57 - 4))  +  uploadedColumn("Off")  +  excelColumn(6, "Off")  +  varianceColumn(6, "Off"));
    errors.push("Sick Leave".padEnd((55 - 11))  +  uploadedColumn("Sick Leave")  +  excelColumn(5, "Sick Leave")  +  varianceColumn(5, "Sick Leave"));
    errors.push("Vacation Leave".padEnd((54 - 15))  +  uploadedColumn("Vacation Leave")  +  excelColumn(4, "Vacation Leave")  +  varianceColumn(4, "Vacation Leave"));
    errors.push("Compasionate Leave".padEnd((49 - 19))  +  uploadedColumn("Compasionate Leave")  +  excelColumn(3, "Compasionate Leave")  +  varianceColumn(3, "Compasionate Leave"));
    errors.push("-");
    errors.push("TOTALS".padEnd((50 - 6))  +  `${total_value_uploaded}`.padEnd(37)  +  `${excel_value_10}`.padEnd(40 + (normal_length - `${excel_value_10}`.length))  +  `${total_value_uploaded - excel_value_10}`);


    // console.log("transformData", transformedData);
    // return;
    setExcelUploadErrors(errors);
    return transformedData;
  };

  const handleXLFileUpload = async (e, editMethod, setRows, table, sheet_name = null) => {
    setIsLoading(true);

    const file = e.target.files[0];
    // CSV
    
    // Papa.parse(file, {
    //   header: true,
    //   complete: (result) => {
    //     const data = result.data;
    //     console.log("data", data);
    //     transformData(data);
    //   }
    // });

    // XLSX
    const reader = new FileReader();
    let newList = [];

    reader.onload = (event) => {
      const arrayBuffer = event.target.result;
      const workbook = XLSX.read(new Uint8Array(arrayBuffer), { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheet_name || sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { defval: "" });

      // console.log(jsonData);
      if (table === "timesheet"){
        newList = transformExcelTimesheetData(jsonData);
      } 
      
      // setImportData(jsonData);
    };

    reader.readAsArrayBuffer(file);
    // Wait for the onload event to finish
    await new Promise(resolve => {
      reader.onloadend = resolve;
    });

    const uploadedList = {
      rows: newList
    }
    console.log("2", uploadedList);

    // console.log(response);
    if (table === "timesheet"){
      // console.log(newList);
      const data = {
        timesheet_data: newList,
        payroll: selectedPayroll,
        paypoint: selectedPayPoint,
        costcode: selectedCostCode,
        saving_or_sending: "saving excel"
      }
      // if (errors.length > 0){
      //   setExcelUploadErrorPopup(true);
      // }
      setExcelUploadErrorPopup(true);

      setImportData(data);
      setIsLoading(false);      
      // await editMethod(data);
      
      // forceRerender();
    } else {
      const response = await editMethod(uploadedList);
      setRows(response.data.data);
    
      setSnackbar({ children: response.data.message, severity: 'success' });
      setIsLoading(false);
    }
  };

  const handleProceedWithExcelImport = async () => {
    setExcelUploadErrorPopup(false);
    await handleSendTimesheetForApprovalClick(importData);
    setImportData({});
  };

  const handleUploadClick = async (variables) => {
    const fileInput = document.getElementById(`${variables["upload_type"]}-file-input`);
    fileInput.value = null; // Reset the value of the file input

    if (variables["upload_type"] === "xl"){
      fileInput.onchange = (event) => {
        handleXLFileUpload(event, variables["editMethod"], variables["setRows"], variables["table"], variables["sheet_name"] || null);
      };

    }

    fileInput.click(); // Open the file picker dialog
  };

  const handleSendTimesheetForApprovalClick = async (variables) => {
    // console.log(modifiedTimesheetData);
    setIsLoading(true);
    let data = {};

    if (variables["saving_or_sending"] !== "saving excel") {
      // console.log("SEND TIME SHEET FOR APPROVAL OR SAVING");
      data = {
        timesheet_data: modifiedTimesheetData,
        payroll: selectedPayroll,
        paypoint: selectedPayPoint,
        costcode: selectedCostCode,
        saving_or_sending: variables["saving_or_sending"]
      }
    } else {
      // console.log("EXCEL UPLOAD");
      data = variables;
    }
    
    console.log("STFA", data);
    const response = await sendTimesheetForApproval(data);

    // console.log(response);

    if (response.status === 200){
      setTimesheetData(response.data.timesheet_data);
      const newData = await buildModifiedTimesheetData("timesheet", [selectedStartDay, selectedEndDay], response.data.timesheet_data, employees, timesheetSettings, dayTypes, jobs, employeeDefaults);
      // console.log(newData);
      setModifiedTimesheetData(newData);

      setSnackbar({ children: response.data.message, severity: 'success' });
    } else {
      setSnackbar({ children: response.data.message, severity: 'error' });
    }
    setIsLoading(false);
  }

  if (isLoading || employees.length === 0) {
    return null;
    // return <CircularProgress />;
  }

  // let availableOrdinalNos = [];
  //   parmCodes.forEach(code => {
  //     availableOrdinalNos.push(`${code.OrdinalNo} - ${code.CodeName}`);
  // });

  let availableEmpNos = [];
    employees.forEach(employee => {
      availableEmpNos.push(employee.EmpNo);
  });

  let allJobs = [];
    jobs.forEach(job => {
      allJobs.push(`${job.name}`);
  });

  let allDayTypes = [];
    dayTypes.forEach(day_type => {
      allDayTypes.push(`${day_type.name}`);
  });

  // setSelectedEmployee(1);
  // console.log(selectedEmployee);
  // console.log("EMPS", employees);

  // console.log(monthList);


  // console.log(timesheetData);
  // console.log(modifiedTimesheetData);

  // DAY VIEW - MODIFIED TIMESHEET GRID

  //MODIFIED TIMESHEET COLUMNS
  const viewColumns = [
    { field: 'EmpNo', headerName: 'EmpNo', width: 70, type: 'singleSelect', valueOptions: availableEmpNos, editable: true },
    { field: 'name', headerName: 'Name', width: 125 },
    { field: 'day_type_id', headerName: 'Day Type', width: 200, 
      type: 'singleSelect',
      valueOptions: allDayTypes,
      valueGetter: (params) => {
        return dayTypes.find(day_type => day_type.id === params.row.day_type_id)?.name;
      },
      valueSetter: (params) => {
        let value_id = dayTypes.find(day_type => day_type.name === params.value)?.id;
        value_id = parseInt(value_id);
        return { ...params.row, day_type_id: value_id};
      },
      editable: true
    },
    { field: 'job_id', headerName: 'Job', width: 200, 
      type: 'singleSelect',
      valueOptions: allJobs,
      valueGetter: (params) => {
        // console.log(params);
        return jobs.find(job => job.id === params.row.job_id)?.name;
      },
      valueSetter: (params) => {
        // console.log(params);
        let value_id = jobs.find(job => job.name === params.value)?.id;
        value_id = parseInt(value_id);
        return { ...params.row, job_id: value_id};
      },
      editable: true
    },
    { field: 'value', headerName: 'Value', width: 80, type: 'float', editable: true },
    { field: 'rate', headerName: 'Rate', width: 80, type: 'float', editable: false, valueGetter: (params) => (Math.round(params.value * 100) / 100).toFixed(2) },
    { field: 'amount', headerName: 'Amount', width: 100, type: 'float', editable: false },
    { field: 'approval_status', headerName: 'Approval Status', width: 100, editable: false },
  ];

  const viewPlusColumns = [...viewColumns];
  viewPlusColumns.unshift({ field: 'date', headerName: 'Day', width: 15, type: 'integer', editable: false, valueGetter: (params) => (params.value.slice(-2)) });

  // console.log(dayTypes);
  // console.log(jobs);

  // const addViewColumns = { };
  const addViewColumns = { EmpNo: employees[0].EmpNo, name: "", day_type_id: dayTypes[0].id, job_id: jobs[0].id, value: "", rate: "", amount: "", approval_status: "" };
  const addViewPlusColumns = { date: selectedStartDay["date"], ...addViewColumns };

  return (
    <>
      <div>
        <Box
          sx={{
            height: 500,
            width: '100%',
            '& .actions': {
              color: 'text.secondary',
            },
            '& .textPrimary': {
              color: 'text.primary',
            },
          }}
        >
          {/* // :DROP DOWNS TO SELECT MONTH, DAY, PAYROLL, PAYPOINT, COSTCODE */}
          <DataFilter month={true} day={true} payroll={true} paypoint={true} costcode={true} />

          <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}
          
          {/* <input id="xl-file-input" type="file" accept=".csv, .xlsx" style={{ display: 'none' }} />  */}
          <input id="xl-file-input" type="file" accept=".xlsx" style={{ display: 'none' }} /> 

          {/* ENTRY GRID */}
          <CollapsableCard 
            cardHeader={`${selectedStartDay["date"]} to ${selectedEndDay["date"]} - TIMESHEET`}
            expanded={true}
            cardContent={
              <CustomDataGrid 
                rows={modifiedTimesheetData} 
                setRows={setModifiedTimesheetData} 
                otherColumns={[
                  selectedStartDay === selectedEndDay ? viewColumns : viewPlusColumns, 
                  selectedStartDay === selectedEndDay ? addViewColumns : addViewPlusColumns, 
                ]} 
                backendCRUD = {{
                  "C": true,
                  "R": "",
                  "U": handleSendTimesheetForApprovalClick,
                  "D": "",
                  "TYPE": "TIMESHEET",
              }}
              actions={true}
              extraButtons={[
                {icon: <AddIcon />, label: "Import", func: handleUploadClick, 
                  func_variables: {upload_type:"xl", table: "timesheet", sheet_name:"Time Book"}, 
                  func_type: "import excel"},
                {icon: <SaveOutlined />, label: "Save Progress", func: handleSendTimesheetForApprovalClick, func_variables: { saving_or_sending:"saving" }, func_type: "import excel"},
              ]}
            />
            }
          />

          <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

          {/* PREVIOUS, NEXT, SEND BUTTONS */}
          <Box sx={{ mt: 2 }} display="flex" justifyContent="space-between" width="100%" gap="10px" mt="20px">
            <Box>
              <Button disabled={disablePreviousButton} onClick={() => handlePreviousOrNextEmployeeOrDay("previous day")} type="button" color="secondary" variant="contained">
                Previous Day
              </Button>

              <Button disabled={disableNextButton}  onClick={() => handlePreviousOrNextEmployeeOrDay("next day")} type="button" color="secondary" variant="contained">
                Next Day
              </Button>
            </Box>

            <Button onClick={() => handleSendTimesheetForApprovalClick({ saving_or_sending: "sending" })} type="button" color="secondary" variant="contained">
              Send Timesheet For Approval
            </Button>
          
          </Box>


          {/* <LoadingOverlay isLoading={isLoading} /> */}
          <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

          
          <Box sx={{ mt: 4 }} /> {/* Add spacing between the cards */}
          
          {/* REJECTED TIMESHEETS */}
          {/* <CollapsableCard 
            cardHeader={"REJECTED TIMESHEETS"}
            expanded={false}
            cardContent={
              <CustomDataGrid 
              rows={modifiedTimesheetData} 
              setRows={setModifiedTimesheetData} 
              otherColumns={[viewColumns, addViewColumns]} 
              backendCRUD = {{
                "C": addLeaveType,
                "R": "",
                "U": "",
                "D": "",
                "TYPE": "LEAVE TYPE",
              }}
              actions={true}
              extraButtons={[
                {icon: <AddIcon />, label: "Import", func: handleUploadClick, func_variables: {upload_type:"xl", leave_setting: "leave_types"}, func_type: "import excel"},
                {icon: <SaveOutlined />, label: "Save Progress", func: handleUploadClick, func_variables: {upload_type:"xl", leave_setting: "leave_types"}, func_type: "import excel"},
              ]}
            />
            }
          />

          <Box sx={{ mt: 2 }} /> */}

          {/* APPROVED TIMESHEETS */}
          {/* <CollapsableCard 
            cardHeader={"APPROVED TIMESHEETS"}
            expanded={false}
            cardContent={
              <CustomDataGrid 
              rows={modifiedTimesheetData} 
              setRows={setModifiedTimesheetData} 
              otherColumns={[viewColumns, addViewColumns]} 
              backendCRUD = {{
                "C": addLeaveType,
                "R": "",
                "U": "",
                "D": "",
                "TYPE": "LEAVE TYPE",
              }}
              actions={true}
              extraButtons={[
                {icon: <AddIcon />, label: "Import", func: handleUploadClick, func_variables: {upload_type:"xl", leave_setting: "leave_types"}, func_type: "import excel"},
                {icon: <SaveOutlined />, label: "Save Progress", func: handleUploadClick, func_variables: {upload_type:"xl", leave_setting: "leave_types"}, func_type: "import excel"},
              ]}
            />
            }
          />

          <Box sx={{ mt: 2 }} /> */}

          {excelUploadErrorPopup && 
            <Popup 
              openPopup = {excelUploadErrorPopup}
              setOpenPopup = {setExcelUploadErrorPopup}
            >
              <Box>
                {excelUploadErrors.map((error, index) => (
                  <Typography key={index} component="pre">{error}</Typography>
                ))}
                <Box display="flex" justifyContent="space-between" width="100%" gap="10px" mt="20px">
                  <Button onClick={() => setExcelUploadErrorPopup(false)} type="button" color="secondary" variant="contained">Cancel</Button>
                  <Button onClick={handleProceedWithExcelImport} type="button" color="secondary" variant="contained">Proceed with Import</Button>
                </Box>
              </Box>
            </Popup>
          }
        </Box>
      </div>
    </>
  );  
};

export default TimesheetPage;